<template>
  <a-config-provider :locale="locale">
    <div id="app" :class="menuHidden?'menue-left-hidden':'menue-left-open'">
      <keep-alive>
        <router-view />
      </keep-alive>
    </div>
  </a-config-provider>
</template>

<script>
import { domTitle, setDocumentTitle } from '@/utils/domUtil'
import { i18nRender } from '@/locales'
import defaultSettings from '@/config/defaultSettings'
import { EventBus } from '@/utils/event-bus.js';
var mqtt = require('mqtt/dist/mqtt.js')
// import './qiankun'
// import reload from '@/mixins/reload'
export default {
  // provide () {
  //  return {
  //    reload: this.reload
  //  }
  // },
  // mixins: [reload],
  data () {
    return {
      // isRouterAlive: true
    }
  },
  
  computed: {
    locale () {
      // 只是为了切换语言时，更新标题
      const { title } = this.$route.meta
      title && (setDocumentTitle(`${i18nRender(title)} - ${domTitle}`))

      return this.$i18n.getLocaleMessage(this.$store.getters.lang).antLocale
    },
    client() {
      console.log('mqttClient>>>>>>>>>>>>>',this.$store.getters.mqttClient);
      
      return this.$store.getters.mqttClient
    }
  },

  updated () {
    document.title = this.$t(defaultSettings.title)
  },
  mounted() {
    // 监听mqtt连接
    EventBus.$on('connectMqtt', () => {
      this.connectMqtt()
    })

    // mqtt订阅服务
    EventBus.$on('subscribeHandle', (subscribe) => {
      console.log('mqtt订阅服务>>>>>>>>',subscribe);
      
      this.subscribeHandle(subscribe)
    })

    // 关闭mqtt连接
    EventBus.$on('closeMqtt', () => {
      this.closeMqtt()
    })


  },
  beforeDestroy() {
    EventBus.$off('connectMqtt');
    EventBus.$off('subscribeHandle');
    EventBus.$off('closeMqtt');
    this.closeMqtt()
  },
  created () {
    console.log('token>>>>>>>',this.$store.getters.token);
    if(this.$store.getters.token) {
      this.connectMqtt()
    }
  },
  methods: {
    /**
     * @desc 关闭mqtt连接
     * @param {  } 
     * @version: 1.0.0
     */
     closeMqtt() {
      if(this.client) {
        this.client.end()
      }
    },
    /**
     * @desc mqtt连接
     * @author 刘
     * @time 2023年06月02日 18:31:19 星期五
     */
    connectMqtt() {
      const that = this
      if(!defaultSettings.mqttSwitch) {
        console.log('mqtt服务关闭>>>>>');
        
        return
      }
      let clientObj = null
      // client = mqtt.connect('ws://emqx.zzcyi.cn:8083/mqtt')
      // const mqttUrl = 'ws://' + this.mqttServer + ':8083/mqtt'
      // client = mqtt.connect('ws://47.104.102.88:8083/mqtt')
      clientObj = mqtt.connect(process.env.VUE_APP_BASE_MQTT_URL)
      // , {
      //   username: this.debug_account,
      //   password: this.debug_password
      // }
      clientObj.on('connect', () => {
        console.log('on connect', new Date().getTime())

        console.log('主题>>>>', that.subscribe)

        this.$store.dispatch('setMqttClient',clientObj)


        console.log('state>>>>>>',this.$store);
        console.log('this.client>>>>>>',this.client);
        


        // this.client.subscribe('11111', function(err, granted) {
        //   console.log('订阅主题>>>>>err>>>>', err, that.subscribe)
        //   console.log('订阅主题>>>>>granted>>>>', granted)
        //   if (!err) {
        //     console.log('订阅成功>>>>')
        //   }
        // })

      }).on('reconnect', () => {
      // uni.showLoading({
      // 	title:'加载中...',
      // 	mask:true
      // })
      // setTimeout(function() {
      // 	uni.hideLoading()
      // }, 3000);
        console.log('重新连接', new Date().getTime())
        
        this.$store.dispatch('setMqttClient',clientObj)
      }).on('close', (err) => {
      // that.setIsMqttConnect(false)
        console.log('链接断开>>>>', err)
        this.$store.dispatch('setMqttClient',null)
      }).on('error', (err) => {
        console.log('on error', err)
      }).on('end', () => {
      // that.setIsMqttConnect(false)
        console.log('on end')
        this.$store.dispatch('setMqttClient',null)
      }).on('message', (topic, message) => {
        console.log('mqtt消息', message.toString())
        // alert(message.toString())

        try {
          const messageObj = JSON.parse(message)
          console.log('接受到的消息>>>', messageObj)
          // logType为"sendToDev"--向设备发送信息
          // logType为"devReply"--设备回复
          // logType为"devLogin"--设备上线
          EventBus.$emit('receiveMqttData', messageObj)
        } catch (e) {
        // TODO handle the exception
        }
      })
    },
    /**
     * @desc 订阅主题
     * @param {  } 
     * @version: 1.0.0
     */
    subscribeHandle(subscribe) {
      console.log('订阅主题>>>>>$store>>>>', this.$store)
      console.log('订阅主题>>>>>client>>>>', this.client)

      this.client.subscribe(subscribe, (err, granted) => {
        console.log('订阅主题>>>>>err>>>>', err, subscribe)
        console.log('订阅主题>>>>>granted>>>>', granted)
        if (!err) {
          console.log('订阅成功>>>>')
        }
      })
    },
    /**
     * @desc 发送数据
     * @param {  }
     * @version: 1.0.0
     */
    sendHandle() {
      console.log('publish>>>>>', this.publish)
      // console.log('sendHex>>>>>', JSON.stringify(this.sendHex))
      if (!this.sendHex) {
        return
      }
      let buffer = this.sendHex;
      if(this.isHex(this.sendHex)) {
        let sendHex = this.sendHex
        if(this.sendHex.length%2 != 0) {
          sendHex = '0'+this.sendHex
        }
        console.log('sendHex>>>>',sendHex);
        
        buffer = Buffer.from(sendHex, 'hex')

      }
      this.client.publish(this.publish, buffer)
    },

  }
}
</script>
<style lang="less">
:root {
  --Tabs--line-color: #1C1F27 !important;
  --Tabs--line-fontSize: 1.8vh !important;
  --Tabs--line-fontWeight: bold !important;
  --Tabs--line-lineHeight: 2.2vh !important;

  // --Tabs--line-onHove-color:#1C1F27 !important;
  --Tabs--line-hover-fontSize: 1.8vh !important;
  --Tabs--line-hover-fontWeight: bold !important;
  --Tabs--line-hover-lineHeight: 2.2vh !important;

  // --Tabs--line-active-color:#1C1F27 !important;
  --Tabs--line-active-fontSize: 1.8vh !important;
  --Tabs--line-active-fontWeight: bold !important;
  --Tabs--line-active-lineHeight: 2.2vh !important;

  // --Table-thead-fontSize: 18px;
  // --Table-thead-color: #000000;

  --Table-searchableForm-backgroundColor: transparent !important;

  // --Form--horizontal-label-widthBase: auto !important;

  --Pagination-height: 1.9rem;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: #DFE1E6;
  // -webkit-box-shadow: inset 0 0 5px rgb(0 21 8.2%);
  // box-shadow: inset 0 0 5px rgb(0 21 8.2%);
  border-radius: 6px 6px 6px 6px;
}

.is-modalOpened {
  overflow: auto !important;
}
</style>
