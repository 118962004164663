// 注册自定义组件渲染器
import { registerRendererByType } from '@/utils/amis-widget/index.esm.js'
import Amap from './amap.vue'

registerRendererByType(Amap, {
  type: 'amappolygon',
  usage: 'formitem',
  weight: 99,
  framework: 'vue',
  detectProps: ['amappolygon_info']
})
