<template>
  <div class="amap-wrapper" ref="modalDom">
    <!-- <a-input v-if="amap_is_search" id="input" v-model="searchName" placeholder="请输入地址查询" class="search-box"/> -->
    <el-amap
      class="amap-box"
      :style="{ width: amap_width, height: amap_height }"
      style="width: 700px;"
      :vid="'amap-vue-search_'+amap_create_time+''"
      :ref="'map_'+amap_create_time+''"
      :center="center"
      :zoom="zoom"
      :plugin="plugin"
      :events="events"
    >
    </el-amap>

    <div class="mouseTool">
      <a-radio-group v-model="type" button-style="solid" @change="drawHandle">
        <a-radio-button value="marker">标记点</a-radio-button>
        <a-radio-button value="polyline">画折线</a-radio-button>
        <a-radio-button value="polygon">画多边形</a-radio-button>
        <a-radio-button value="rectangle">画矩形</a-radio-button>
        <!-- <a-radio-button value="circle">画圆</a-radio-button> -->
        <a-radio-button value="clear">清除</a-radio-button>
      </a-radio-group>
      <a-button v-if="type!='marker'" @click="visible=true">设置样式</a-button>
    </div>
    <a-modal style="z-index: 9999999999999;" :getContainer="() => $refs.modalDom" v-model="visible" :title="'设置样式-'+typeName" @ok="drawHandle">
      <a-form-model v-if="type=='polyline'" ref="form" :model="polylineForm">
        <a-form-model-item label="线条">
          <a-radio-group v-model="polylineForm.strokeStyle" button-style="solid">
            <a-radio-button value="solid">
              实线
            </a-radio-button>
            <a-radio-button value="dashed">
              虚线
            </a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="颜色">
          <a-input type="color" v-model="polylineForm.strokeColor" />
        </a-form-model-item>
        <a-form-model-item label="线宽">
          <!-- <a-slider v-model:value="polylineForm.strokeWeight" :min="1" :max="20" /> -->
          <!-- <slider v-model="polylineForm.strokeWeight" :min="1" :max="20" /> -->
          <a-input-number v-model="polylineForm.strokeWeight" :min="1" :max="20" />
        </a-form-model-item>
      </a-form-model>

      <a-form-model v-if="type=='polygon'" ref="form" :model="polygonForm">
        <a-form-model-item label="颜色">
          <a-input type="color" v-model="polygonForm.fillColor" />
        </a-form-model-item>
        <a-form-model-item label="透明度">
          <a-input-number v-model="polygonForm.fillOpacity" :min="0" :max="1" :step="0.1" />
        </a-form-model-item>
        <a-form-model-item label="轮廓线宽">
          <!-- <a-slider v-model:value="polylineForm.strokeWeight" :min="1" :max="20" /> -->
          <!-- <slider v-model="polylineForm.strokeWeight" :min="1" :max="20" /> -->
          <a-input-number v-model="polygonForm.strokeWeight" :min="1" :max="20" />
        </a-form-model-item>
      </a-form-model>

      <a-form-model v-if="type=='rectangle'" ref="form" :model="rectangleForm">
        <a-form-model-item label="颜色">
          <a-input type="color" v-model="rectangleForm.fillColor" />
        </a-form-model-item>
        <a-form-model-item label="透明度">
          <a-input-number v-model="rectangleForm.fillOpacity" :min="0" :max="1" :step="0.1" />
        </a-form-model-item>
        <a-form-model-item label="轮廓线宽">
          <!-- <a-slider v-model:value="polylineForm.strokeWeight" :min="1" :max="20" /> -->
          <!-- <slider v-model="polylineForm.strokeWeight" :min="1" :max="20" /> -->
          <a-input-number v-model="rectangleForm.strokeWeight" :min="1" :max="20" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
// 高德地图
import VueAMap from 'vue-amap'
import { AMapManager } from 'vue-amap'
const amapManager = new AMapManager()
// import {ElAmap, ElAmapMouseTool} from "vue-amap";

import { Slider } from 'ant-design-vue';
var self = null
export default {
  components:{
    // ElAmap, 
    Slider
  },
  props: {
    width: {
      type: String || Number,
      default: '90%'
    },
    height: {
      type: String || Number,
      default: '500px'
    },
    isSearch: {
      type: Boolean,
      default: true
    },
    lat: {
      type: [String, Number],
      default: null
    },
    lng: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    self = this
    return {
      amapManager,
      searchName: null,
      zoom: 6,
      center: [116.396304, 39.909847],
      events: null,
      markers: [],
      // polygons:[],
      latitude: '11111111',
      searchOption: {
        city: '',
        citylimit: false
      },
      plugin: [],
      errorInfo: {
        'INVALID_USER_KEY': 'key不正确或过期',
        'SERVICE_NOT_AVAILABLE': '服务不可用',
        'INVALID_USER_SCODE': '安全码未通过验证',
        'INVALID_USER_DOMAIN': '绑定域名无效',
        'USERKEY_PLAT_NOMATCH': '请求key与绑定平台不符',
        'NOT_SUPPORT_HTTPS': '服务不支持https请求',
        'INSUFFICIENT_PRIVILEGES': '权限不足，服务请求被拒绝',
        'USER_KEY_RECYCLED': 'Key被删除',
        'INVALID_PARAMS': '请求参数非法',
        'MISSING_REQUIRED_PARAMS': '缺少必填参数',
        'UNKNOWN_ERROR': '其他未知错误',
        'OUT_OF_SERVICE': '规划点（包括起点、终点、途经点）不在中国陆地范围内',
        'NO_ROADS_NEARBY': '规划点（起点、终点、途经点）附近搜不到路',
        'ROUTE_FAIL': '路线计算失败，通常是由于道路连通关系导致',
        'OVER_DIRECTION_RANGE': '起点终点距离过长',
        'ENGINE_RESPONSE_DATA_ERROR': '服务响应失败'
      },
      mouseTool: null,
      created:false,
      type:'marker',
      overlays:[],
      mapObj:null,
      geoJSONObj:null,
      visible:false,
      typeName:'标记点',
      // 折线配置
      polylineForm:{
        strokeStyle:'solid',
        strokeColor:'#80d8ff',
        strokeWeight:1,
      },
      // 多边形配置
      polygonForm:{
        fillColor:'#00b0ff',
        fillOpacity:0.2,
        strokeWeight:'1',
        strokeColor:"#00b0ff"
      },
      // 矩形配置
      rectangleForm:{
        fillColor:'#00b0ff',
        fillOpacity:0.2,
        strokeWeight:'1',
        strokeColor:"#00b0ff"
      }
    }
  },
  computed: {
  },
  beforeMount() {
    // console.log('beforeMount>>>>>>>>>>>',this.amap_key);
    
    VueAMap.initAMapApiLoader({
      key: this.amap_key,
      plugin: ['AMap.Autocomplete', 'AMap.Geocoder', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'MarkerClusterer','AMap.MouseTool','AMap.GeoJSON'],
      v: '1.4.4'
    })
    window._AMapSecurityConfig = {
      securityJsCode: this.amap_security
    }
  },
  mounted() {
    console.log('self>>>>>1>>>>>', this)
    this.events = {
      init:(o) => {
        console.log('init>>>>map>>>>>',o);
        this.mapObj = o
        this.mouseTool = new AMap.MouseTool(o); 
        this.geoJSONObj = new AMap.GeoJSON({
          geoJSON:null,
          getPolygon: function(geojson, lnglats) {
            console.log('getPolygon>>>>>>geojson>>>>',geojson);
            console.log('getPolygon>>>>>>lnglats>>>>',lnglats);
            
            return new AMap.Polygon({
              path: lnglats,
              ...geojson.properties
            });
          },
          getPolyline: function(geojson, lnglats) {
            console.log('getPolyline>>>>>>geojson>>>>',geojson);
            console.log('getPolyline>>>>>>lnglats>>>>',lnglats);
            
            return new AMap.Polyline({
              path: lnglats,
              ...geojson.properties
            });
          },
          getMarker: function(geojson, lnglats) {
            console.log('getMarker>>>>>>geojson>>>>',geojson);
            console.log('getMarker>>>>>>lnglats>>>>',lnglats);
            
            return new AMap.Marker({
              position: lnglats,
              ...geojson.properties
            });
          },
        })
        this.geoJSONObj.setMap(o)

        console.log('mouseTool>>>>>>>>>>',this.mouseTool)
        //监听draw事件可获取画好的覆盖物
        this.overlays = [];
        this.mouseTool.on('draw',(e) =>{
          console.log('绘画结束>>>>>',e);
          
          this.overlays.push(e.obj);
          let overlayObj = e.obj
          let extDataObj = overlayObj.De
          switch (e.obj.CLASS_NAME) {
            case 'AMap.Polygon':
              overlayObj.De['extData'] = {
                _geoJsonProperties:{
                  fillColor:extDataObj.fillColor,
                  fillOpacity:extDataObj.fillOpacity,
                  strokeColor:extDataObj.strokeColor,
                  strokeDasharray:extDataObj.strokeDasharray,
                  strokeOpacity:extDataObj.strokeOpacity,
                  strokeStyle:extDataObj.strokeStyle,
                  strokeWeight:extDataObj.strokeWeight,
                }
              }
              break;
            case 'AMap.Marker':
              // overlayObj.De['extData'] = {
              //   _geoJsonProperties:{
              //     fillColor:extDataObj.fillColor,
              //     fillOpacity:extDataObj.fillOpacity,
              //     strokeColor:extDataObj.strokeColor,
              //     strokeDasharray:extDataObj.strokeDasharray,
              //     strokeOpacity:extDataObj.strokeOpacity,
              //     strokeStyle:extDataObj.strokeStyle,
              //     strokeWeight:extDataObj.strokeWeight,
              //   }
              // }
              break;
            case 'AMap.Polyline':
              overlayObj.De['extData'] = {
                _geoJsonProperties:{
                  borderWeight:extDataObj.borderWeight,
                  outlineColor:extDataObj.outlineColor,
                  dirColor:extDataObj.dirColor,
                  strokeColor:extDataObj.strokeColor,
                  strokeDasharray:extDataObj.strokeDasharray,
                  strokeOpacity:extDataObj.strokeOpacity,
                  strokeStyle:extDataObj.strokeStyle,
                  strokeWeight:extDataObj.strokeWeight,
                }
              }
              break;
            default:
              break;
          }
          console.log('overlayObj>>>>>>>>>>>>>',overlayObj);
          
          this.geoJSONObj.addOverlay(overlayObj)
          
          console.log('转换>>>toGeoJSON>>',this.geoJSONObj.toGeoJSON());
          self.setValidData(self.$schema.amap_is_polygon_path, this.geoJSONObj.toGeoJSON())
          
        }) 
      },
      click: (e) => {
        // const that = this
        if (!e) {
          return
        }
        if (!this.amap_is_click&&!this.amap_is_polygon) {
          // 不支持点击并且不支持多边形
          return
        }
        
      }
    }
    this.setValidData('amapDetailInfo', '')
  },
  created() {
    console.log('created>>>>>>>>>>',this.amap_key);
    self = this;
    this.type = 'marker'
    setTimeout(() => {
      this.$nextTick(() => {
        this.init()
      })
    }, 1000)
  },
  updated() {
    if (this.renderChild && this.body) {
      this.renderChild('body', this.body, 'ReactChildBody')
    }
    // console.log('updated>>>>>data>>>>>amap_list>>>>', this.$data.data[this.amap_list])
  },
  methods: {
    draw(e, target) {
      console.log('绘制完成 : ', e, target)
    },
    drawHandle() {
      console.log('drawHandle>>>>>>>>>>>',this.type);
      this.visible = false
      switch (this.type) {
        case 'marker':
          this.typeName = '标记点'
          this.mouseTool.marker({
            //同Marker的Option设置
          });
          break;

        case 'polyline':
          this.typeName = '折线'
          this.mouseTool.polyline(this.polylineForm);
          break;
        case 'polygon':
          this.typeName = '多边形'
          let polygonObj = JSON.parse(JSON.stringify(this.polygonForm))
          polygonObj.strokeColor = polygonObj.fillColor
          this.mouseTool.polygon(polygonObj);
          break;
        case 'rectangle':
          this.typeName = '矩形'
          let rectangleObj = JSON.parse(JSON.stringify(this.rectangleForm))
          rectangleObj.strokeColor = rectangleObj.fillColor
          this.mouseTool.rectangle(rectangleObj);
          break;
        case 'circle':
          this.typeName = '圆'
          this.mouseTool.circle({
            fillColor:'#00b0ff',
            strokeColor:'#80d8ff'
            //同Circle的Option设置
          });
          break;
        case 'clear':
          this.mouseTool.close(true)//关闭，并清除覆盖物
          this.geoJSONObj.clearOverlays() // 清空覆盖物
          // this.mapObj.remove(this.overlays)
          this.overlays = [];
          self.setValidData(self.$schema.amap_is_polygon_path, this.geoJSONObj.toGeoJSON())

          break;


          
      
        default:
          break;
      }
    },
    changeMarker (newType) {
      this.type = newType;
    },
    setValidData(key, value) {
      if (this.store.storeType === 'ServiceStore') { // 上级作用域为顶层作用域或者Service
        this.store.reInitData({ [key]: value })
      } else if (this.store.storeType === 'FormStore') { // 上级作用域为表单
        this.store.setValueByName(key, value)
      }
    },
    init() {
      console.log('init>>>>>', this.$data['data'])
      // console.log('init>>>>>amap_is_show_more_marker>>>>', this.amap_is_show_more_marker)
      // 如果需要有电子围栏数据
      if (this.$data.data[this.amap_list]) {
        // console.log('init>>>>>amap_list>>>>', this.amap_list)
        // console.log('init>>>>>data>>>>>amap_list>>>>', this.$data.data[this.amap_list])
        let mapList = []
        mapList = this.$data.data[this.amap_list]

        if(typeof mapList != 'object') {
          try {
            mapList = JSON.parse(mapList)
          } catch (error) {
            
          }
        }

        if (mapList && mapList.length > 0) {
          this.center = []
          if(this.geoJSONObj) {
            // 如果有geoJSON对象
            this.geoJSONObj.importData(mapList)
          }
          if (this.center.length === 0) {
            this.center = mapList[0].geometry.coordinates[0]
          }
        }

      }
      //  else {
      //   const lng = this.$data.data[this.amap_longitude]
      //   // || 116.396304
      //   const lat = this.$data.data[this.amap_latitude]
      //   // || 39.909847
      //   const label = this.$data.data[this.amap_name]
      //   console.log('数据>>>>>>',this.$data.data);
      //   console.log('lng>>>>>>',lng);
      //   console.log('lat>>>>>>',lat);
        
      //   if (lng && lat) {
      //     this.center = [lng, lat]
      //     this.markers = [
      //       { lng: this.center[0], lat: this.center[1], name: label }
      //     ]
      //     console.log('markers>>>>>>',this.markers);
          
      //   } else {
      //     this.center = [116.396304, 39.909847]
      //   }
      // }
    },

    geoCoder(positionArr) {
      // 获取地址经纬度和省市区
      var that = this
      // 这里通过高德 SDK 完成。
      // eslint-disable-next-line no-undef
      var geocoder = new AMap.Geocoder({
        radius: 1000,
        extensions: 'all'
      })
      console.log('positionArr>>>>>', positionArr)

      geocoder.getAddress(positionArr, (status, result) => {
        console.log('getAddress>>>>status>>>>>', status)
        console.log('getAddress>>>>result>>>>', result)

        if (status === 'complete' && result.info === 'OK') {
          if (result && result.regeocode) {
            const detailObj = {
              addressComponent: result.regeocode.addressComponent,
              detailedAddress: result.regeocode.formattedAddress,
              lng: positionArr[0],
              lat: positionArr[1]
            }

            that.amapInfo = detailObj
            console.log('markers>>>>>>>>>>>>>>>',that.markers);

            let markersArr = JSON.parse(JSON.stringify(that.markers))
            markersArr.push({
              lng: positionArr[0],
              lat: positionArr[1],
              name: result.regeocode.formattedAddress,
              address: result.regeocode.formattedAddress
            })

            that.markers = markersArr
            
            console.log('地址信息>>>>', detailObj)
            console.log('地址标记点信息>>>>', that.markers)
            console.log('地址标记点信息>>>>data', that.$data)
            console.log('地址标记点信息>>>>data', that.$data.amap_longitude)
            console.log('地址标记点信息>>>>data', that.$data.data)
            const dataCopy = JSON.parse(JSON.stringify(that.$data.data))
            dataCopy[that.$data.amap_longitude] = detailObj.lng
            dataCopy[that.amap_latitude] = detailObj.lat
            dataCopy[that.amap_info] = detailObj
            // that.$data.data = dataCopy
            that.$set(that.$data, 'defaultData', dataCopy)
            console.log('设置标记点之后的数据>>>>>>', that.$data)
            that.$emit('update:amap_info', detailObj)
            that.$data.amap_info = detailObj
            if(this.$schema.amap_info) {
              this.setValidData(this.$schema.amap_info, detailObj.detailedAddress)
            }

            if(this.$schema.amap_longitude) {
              this.setValidData(this.$schema.amap_longitude, detailObj.lng)
            }

            if(this.$schema.amap_latitude) {
              this.setValidData(this.$schema.amap_latitude, detailObj.lat)
            }

            if(this.$schema.province) {
              this.setValidData(this.$schema.province, detailObj.addressComponent.province)
            }

            if(this.$schema.city) {
              this.setValidData(this.$schema.city, detailObj.addressComponent.city || detailObj.addressComponent.province)
            }

            if(this.$schema.area) {
              this.setValidData(this.$schema.area, detailObj.addressComponent.district)
            }

            if(this.$schema.township) {
              this.setValidData(this.$schema.township, detailObj.addressComponent.township)
            }

            this.setValidData('amapDetailInfo', detailObj.addressComponent)
          }
        } else {
          if (that.errorInfo[result]) {
            that.$message.error(that.errorInfo[result])
          }
        }
      })
    
      
    },
    /**
     * 添加amis事件动作:
     * 在这里设置自定义组件对外暴露的动作，其他组件可以通过组件动作触发自定义组件的对应动作
     */
     doAction(action, args) {
      const actionType = action ? action.actionType : ''
      if (actionType === 'toast') {
        // 接收外部组件的事件动作'message'
        console.log(args)
        alert('您触发了自定义组件的事件动作[message]')
      } else {
        console.log('自定义组件中监听到的事件动作：', action, ', 事件参数：', args)
      }
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep{
  .el-vue-search-box-container {
    width: 270px;
  }
}
.amap-box {
  ::v-deep{
    .amap-marker-label {
      transform: translate(-40%, -24px);
      border: none;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
    }
  }
}

.amap-wrapper {
  text-align: left;
  position: relative;
}

.mouseTool {
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 20px;
  border-radius: 20px;
  z-index: 99999999;
}
.amap-box {
  width: 90%;
  // margin-left: 5%;
}
.search-box {
  width: 30%;
  position: absolute;
  top: 15px;
  left: 3%;
  z-index: 999;
}
</style>
