<template>
  <div id="userLayout" :class="['user-layout-wrapper', isMobile && 'mobile']">
    <div class="container" :class="['loginpage-'+defaultSettings.loginType]">
      <!-- 登录页顶部logo和功能区 -->
      <div v-if="defaultSettings.isLoginLeftLogo" class="home-top">
        <img src="@/assets/login/11.png" >
        <SelectLang place="login"></SelectLang>
      </div>
      <!-- 左侧内容展示区 -->
      <div v-if="defaultSettings.loginType!=7" class="ad-pic" :class="defaultSettings.loginType==6?'type-6':''">
        <div>{{ defaultSettings.loginTip }}</div>
        <div class="ad-pic-fbt">{{ defaultSettings.loginTip2 }}</div>
        <img v-if="defaultSettings.loginType!=6&&defaultSettings.loginType!=7" :src="require(`@/assets/homeImg/left-ad-${defaultSettings.loginType}.png`)">
      </div>

      <!-- 右侧登录信息 -->
      <div class="login-container">
        <div class="login-weaper  animated bounceInDown">
          <p v-if="pageType=='login'" class="login-tip">{{ defaultSettings.title }}</p>
          <p v-if="['forgetPassword','wxLogin','aliLogin'].includes(pageType)" class="login-tip">
            <img style="height: 24px;margin-right: 13px;cursor: pointer;" src="@/assets/login/9.png" @click="openLogin">
            <span v-if="pageType=='forgetPassword'">忘记密码</span>
            <span v-if="pageType=='wxLogin'">微信登录</span>
            <span v-if="pageType=='aliLogin'">支付宝登录</span>
          </p>
          <a-tabs
            v-if="pageType=='login'"
            v-model="activeKey"
            :defaultActiveKey="activeKey"
            :size="'large'"
            :tabBarStyle="{
              'display':'flex','justify-content':'flex-start','margin-bottom':'2.7vh','font-size':'18px',
              'font-family': '思源-Regular','border-bottom':'none'
            }"
            @change="tabChange">
            <a-tab-pane key="1" tab="密码登录"></a-tab-pane>
            <a-tab-pane v-if="defaultSettings.isCodeLogin" key="2" tab="验证码登录"></a-tab-pane>
          </a-tabs>
          <router-view ref="loginRef"/>
        </div>

      </div>

      <!-- 底部信息 -->
      <div class="footer">
        <span class="links font14R">Copyright</span>
        <span class="links font14R">2023</span>
        <span class="links font14R">All Rights Reserved.</span>
        <span class="links font14R">{{ defaultSettings.title }}</span>
        <span class="links font14R">
          <a
            href="https://beian.miit.gov.cn/#/Integrated/index">  {{ defaultSettings.gov }} </a>
        </span>

      </div>
      <!-- <div class="top">
        <div class="header">
          <a href="/">
            <img src="~@/assets/logo.png" class="logo" alt="logo">
            <span class="title">XXX后台管理系统</span>
          </a>
        </div>
        <div class="desc">

        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { deviceMixin } from '@/store/device-mixin'
import defaultSettings from '@/config/defaultSettings'
import SelectLang from '@/components/SelectLang'
export default {
  name: 'UserLayout',
  mixins: [deviceMixin],
  components: {
    SelectLang
  },
  data() {
    return {
      defaultSettings,
      activeKey: '1',
      pageType: 'login' // login 登录页面  forgetPassword  忘记密码
    }
  },
  mounted () {
    document.body.classList.add('userLayout')
  },
  beforeDestroy () {
    document.body.classList.remove('userLayout')
  },
  updated() {
    console.log('updated>>>>>', this.$route)
    this.pageType = this.$route?.name || 'login'
  },
  methods: {
    tabChange(activeKey) {
      console.log('activeKey>>>>>>', activeKey)
      console.log(this.$refs.loginRef)
      this.$refs.loginRef.getCode(Number(activeKey) !== 1)
    },
    // 打开登录
    openLogin() {
      this.$router.push({ path: '/user/login' }).catch(() => { })
    }
  }
}
</script>

<style lang="less" scoped>
#userLayout.user-layout-wrapper {
    height: 100%;

    &.mobile {
      .container {
        .main {
          max-width: 368px;
          width: 98%;
        }
      }
    }

    .container {
      // width: 100%;
      min-height: 100%;
      // width: 1920px;
      width: 100vw;
      height: 100vh;
      // height: 1080px;
      background:#d9e2ef url('~@/assets/homeImg/br.png') no-repeat 100% 46px;

      // background: #F6F9FD url(~@/assets/background.svg) no-repeat 50%;
      // background-size: 100%;
      // padding: 110px 0 144px;
      position: relative;
      overflow: hidden;

      &.loginpage-6 {
        background: url('~@/assets/homeImg/bg-1.png') no-repeat 100% 100%;
        background-size: 100% 100%;
        margin: 0;
        max-width: 100%;
      }

      &.loginpage-7 {
        background: url('~@/assets/homeImg/bg-2.png') no-repeat 100% 100%;
        background-size: 100% 100%;
        margin: 0;
        max-width: 100%;
      }

      a {
        text-decoration: none;
      }

      .home-top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        // padding-top: 0.92vh;
        padding-top: 4.21vh;
        // padding-top: 2.87vh;
        // padding-left: 6.25vw;
        // padding-left: 3.22vw;
        padding-left: 2.76vw;
        padding-right: 1.92vw;
        // height: 46px;
        // height: 4.26vh;
        // background: #FFFFFF;
        position: absolute;
        top: 0;
        left: 0;
        img{
          // width: 70px;
          // height: 30px;
          // width: 3.6vw;
          // height: 2.7vh;
          // height: 3.52vh;
          height: 4.81vh;
          // margin: 0 30px;
        }
      }

      .ad-pic{
        position: absolute;
        // width: 500px;
        // width: 26.04vw;
        // height: 500px;
        // top: 190px;
        top: 18.28vh;
        // left: 430px;
        // left: 20.73vw;
        left: 14.84vw;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        div{
          width: 100%;
          // font-size: 56px;
          font-size: 5.1vh;
          font-family: Alibaba PuHuiTi-Bold, Alibaba PuHuiTi;
          font-weight: bold;
          color: #1C1F27;
          word-break: normal;
          overflow: hidden;
          margin-bottom: 13px;

          &.ad-pic-fbt {
            // font-size: 20px;
            font-size: 2.96vh;
            font-family: Microsoft PhagsPa-Regular, Microsoft PhagsPa;
            font-weight: 400;
            color: #1C1F27;
            line-height: 2.96vh;
            margin-bottom: 33px;

          }
        }

        img{
          width: 425px;
          height: 362px;
        }

        &.type-6 {
          // height: 100%;
          top: 33.8vh;

          div{
            color: #FFFFFF;

            &.ad-pic-fbt {
              color: #FFFFFF;

            }
          }
        }
      }

      .login-container {
        // position: relative;
        // width: 473px;
        // width: 24.6vw;
        // height: auto;
        width: 28.6vw;
        // height: 59.1vh;
        min-height: 59.1vh;
        padding-left: 3.3vw;
        padding-right: 3.3vw;
        // padding-bottom: 8.51vh;
        background: #FFFFFF;
        border-radius: 12px;
        // margin-left: 1132px;
        // margin-top: 145px;
        position: absolute;
        // top: 190px;
        // left: 1130px;
        // top: 40.49vh;
        // top: 18.28vh;
        // left: 58.96vw;
        top: 20.5vh;
        right: 6vw;

        /deep/.ant-tabs .ant-tabs-large-bar .ant-tabs-tab{
          padding: 0 0 11px;
        }

        /deep/.ant-tabs-tab {
          font-size: 18px;
          // font-size: 1.6vh;

          line-height: 1;
          color: #666666;

          &.ant-tabs-tab-active {
            color: #333333;
          }
        }

        /deep/.ant-tabs-ink-bar {
          width: 40px !important;
          height: 4px;
          background: #2878FF;
        }
      }
      .login-tip {
        // font-size: 3.14vh;
        font-size: 34px;
        font-family: '思源-Medium';
        font-weight: 400;
        line-height: 1;
        color: #333333;
        text-align: left;
        margin: 0;
        padding: 4.2vh 0 4vh;
      }

      // .login-border {
      //   width: 376px;
      // }

      .top {
        text-align: center;

        .header {
          height: 44px;
          line-height: 44px;

          .badge {
            position: absolute;
            display: inline-block;
            line-height: 1;
            vertical-align: middle;
            margin-left: -12px;
            margin-top: -10px;
            opacity: 0.8;
          }

          .logo {
            height: 44px;
            vertical-align: top;
            margin-right: 16px;
            border-style: none;
          }

          .title {
            font-size: 33px;
            color: rgba(0, 0, 0, .85);
            font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
            font-weight: 600;
            position: relative;
            top: 2px;
          }
        }
        .desc {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.45);
          margin-top: 12px;
          margin-bottom: 40px;
        }
      }

      .main {
        // min-width: 260px;
        // width: 368px;
        // width: 100%;
        // margin: 0 auto;
        margin: 0 2.5vw;
      }

      .footer {
        position: absolute;
        width: 100%;
        bottom: 0;
        padding: 0 16px;
        margin: 0 0 23px;
        text-align: center;

        .links {

          color: #666666;
          margin-right: 10px;
          a {
            color: #666666;
            &:not(:last-child) {
              margin-right: 40px;
            }
          }
        }
      }

      @media screen and (max-width:1400px) {
        .ad-pic {
          left: 5.84vh;
        }
      }

      @media screen and (max-width:1200px) {
        .ad-pic {
          width: 100%;
          text-align: center;
          top: 16.8vh !important;
          left: 0;
        }

        .login-container {
          width: 46.6vw;
          top: 35.28vh !important;
          left: 50%;
          margin-left: -23.3vw;
        }
      }

      @media screen and (max-width:500px) {
        .ad-pic {
          width: 100%;
          text-align: center;
          top: 12.8vh !important;
          left: 0;
        }

        .login-container {
          width: 83.6vw;
          top: 37.28vh !important;
          left: auto;
          margin-left: 8.2vw;
        }
      }
    }

  }

</style>
