/* eslint-disable no-template-curly-in-string */
/**
 * @file 编辑器扩展, 增加自定义组件
 */
import { BasePlugin, registerEditorPlugin } from 'amis-editor'

export class AmapPlugin extends BasePlugin {
  // 关联渲染器名字
  rendererName = 'press';
  $schema = '/schemas/UnkownSchema.json';
  notRenderFormZone = true;
  // 组件名称（组件面板显示的Title）
  name = '长按按钮';
  order=0; // 排序
  description = '长按按钮展示信息';
  tags = ['布局容器', 'A自定义'];
  icon = 'fa fa-file-code-o';
  scaffold = { // 插入到页面时需要
    type: 'press',
    isValidateSave: true,
    'press_text': '按钮',
    'press_width': '',
    'press_height': '',
    'press_padding_top': '',
    'press_padding_right': '',
    'press_padding_bottom': '',
    'press_padding_left': '',
    'press_margin_top': '',
    'press_margin_right': '',
    'press_margin_bottom': '',
    'press_margin_left': '',
    'press_bg': '#e6f0ff',
    'press_active_bg': '#2878ff',
    'press_api_url':'',
    'press_source':'items',
    'press_data':'',
    'press_create_time':new Date().getTime()
    // body: {
    //   press_name: '名称',
    //   press_value: '10'
    // }

    // body: [ // 容器类字段
    //  {
    //    'type': 'press',
    //    'tpl': '自定义容器区',
    //    'inline': false
    //  }
    // ]
  };
  previewSchema = { // 组件面板预览时需要
    type: 'press',
    label: 'press'
  };

  // 容器类组件必需字段
  // regions = [
  //   {
  //     key: 'body',
  //     label: '内容区'
  //   }
  // ];

  panelTitle = '配置'; // 右侧属性面板Title
  panelControls = [ // 右侧属性面板配置项
    {
      type: 'tabs',
      tabsMode: 'line',
      className: 'editor-prop-config-tabs',
      contentClassName: 'no-border editor-prop-config-tabs-cont hoverShowScrollBar',
      linksClassName: 'editor-prop-config-tabs-links aa',
      tabs: [
        {
          title: '常规',
          className: 'p-none',
          controls: [
            {
              'type': 'collapse-group',
              className: 'ae-formItemControl ae-styleControl',
              'activeKey': [
                'press_11', 'press_12'
              ],
              body: [
                {
                  'type': 'collapse',
                  'key': 'press_11',
                  'header': '基本',
                  headingClassName: 'ae-formItemControl-header ae-Collapse-header',
                  bodyClassName: 'ae-formItemControl-body',
                  'body': [
                    {
                      type: 'input-text',
                      name: 'press_text',
                      label: '名称',
                      value: '按钮',
                      // 'mode': 'inline',
                      'required': true

                    }
                  ]
                }
              ]
            }

          ]
        },
        {
          title: '外观',
          className: 'p-none',
          controls: [
            {
              'type': 'collapse-group',
              className: 'ae-formItemControl ae-styleControl',
              'activeKey': [
                'press_21', 'press_22', 'press_23'
              ],
              'body': [
                {
                  'type': 'collapse',
                  'key': 'press_21',
                  'header': '基本',
                  headingClassName: 'ae-formItemControl-header ae-Collapse-header',
                  bodyClassName: 'ae-formItemControl-body',
                  'body': [
                    {
                      type: 'input-text',
                      name: 'press_width',
                      label: '宽度',
                      value: ''
                    },
                    {
                      type: 'input-text',
                      name: 'press_height',
                      label: '高度',
                      value: ''
                    },
                    {
                      type: 'input-text',
                      name: 'press_padding_top',
                      label: '内边距-上',
                      value: ''
                    },
                    {
                      type: 'input-text',
                      name: 'press_padding_right',
                      label: '内边距-右',
                      value: ''
                    },
                    {
                      type: 'input-text',
                      name: 'press_padding_bottom',
                      label: '内边距-下',
                      value: ''
                    },
                    {
                      type: 'input-text',
                      name: 'press_padding_left',
                      label: '内边距-左',
                      value: ''
                    },
                    {
                      type: 'input-text',
                      name: 'press_margin_top',
                      label: '外边距-上',
                      value: ''
                    },
                    {
                      type: 'input-text',
                      name: 'press_margin_right',
                      label: '外边距-右',
                      value: ''
                    },
                    {
                      type: 'input-text',
                      name: 'press_margin_bottom',
                      label: '外边距-下',
                      value: ''
                    },
                    {
                      type: 'input-text',
                      name: 'press_margin_left',
                      label: '外边距-左',
                      value: ''
                    },
                    {
                      type: 'input-color',
                      name: 'press_bg',
                      label: '按钮颜色',
                      value: '#e6f0ff',
                      'required': true
                      // 'mode': 'inline'
                    },
                    {
                      type: 'input-color',
                      name: 'press_active_bg',
                      label: '点击时颜色',
                      value: '#2878ff',
                      'required': true
                      // 'mode': 'inline'
                    }

                  ]
                }
              ]
            }
          ]
        },
        {
          title: '数据',
          className: 'p-none',
          controls: [
            {
              'type': 'collapse-group',
              className: 'ae-formItemControl ae-styleControl',
              'activeKey': [
                'press_31', 'press_32'
              ],
              'body': [
                {
                  'type': 'collapse',
                  'key': 'press_31',
                  'header': '绑定字段',
                  headingClassName: 'ae-formItemControl-header ae-Collapse-header',
                  bodyClassName: 'ae-formItemControl-body',
                  'body': [
                    {
                      type: 'input-text',
                      name: 'press_api_url',
                      label: '接口',
                      value: 'items'
                      // 'mode': 'inline'
                    },
                    {
                      type: 'input-text',
                      name: 'press_method',
                      label: 'method',
                      value: 'post'
                    },
                    {
                      type: 'input-kv',
                      label: '接口提交数据对象',
                      name: 'press_data',
                      keyPlaceholder: '提交的key',
                      valuePlaceholder: '对应的value',
                      'required': true
                    }

                  ]
                }
              ]
            }
          ]
        }
      ]

    }

  ];
}

registerEditorPlugin(AmapPlugin)
