/* eslint-disable no-template-curly-in-string */
/**
 * @file 编辑器扩展, 增加自定义组件
 */
import { BasePlugin, registerEditorPlugin } from 'amis-editor'

export class InfoGridPlugin extends BasePlugin {
  // 关联渲染器名字
  rendererName = 'cham-iframe-screenshot';
  $schema = '/schemas/UnkownSchema.json';
  notRenderFormZone = true;
  // 组件名称（组件面板显示的Title）
  name = 'iframe截图';
  order = 0; // 排序
  description = 'iframe截图';
  tags = ['A自定义'];
  icon = 'fa fa-file-code-o';
  scaffold = {
    'type': 'container',
    'body': [
      {
        'type': 'iframe',
        'src': 'http://192.168.1.239:8080/#/pages/tabBar/my/my',
        'id': 'u:f13967954d07',
        'width': '268px',
        'height': '95%',
        'wrapperCustomStyle': {},
        'themeCss': {
          'baseControlClassName': {
            'padding-and-margin:default': {
              'marginTop': '5%'
            },
            'radius:default': {
              'top-left-border-radius': '26px',
              'top-right-border-radius': '26px',
              'bottom-left-border-radius': '26px',
              'bottom-right-border-radius': '26px'
            }
          }
        },
        'onEvent': {
          'detail': {
            'actions': [
              {
                'actionType': 'custom',
                'script': "/* 自定义JS使用说明：\n  * 1.动作执行函数doAction，可以执行所有类型的动作\n  * 2.通过上下文对象context可以获取当前组件实例，例如context.props可以获取该组件相关属性\n  * 3.事件对象event，在doAction之后执行event.stopPropagation();可以阻止后续动作执行\n*/\nconsole.log(context, 'context')\nconsole.log(event.data)\nconsole.log(window, 'window')\nvar formData = new FormData();\nvar file = new File([event.data.file], new Date().getTime()+'.jpg', {type: 'image/jpg'})\nformData.append('file', file);\n\nfetch('http://47.92.37.224:7078/admin-api/common/local/file/amisUpload', {\n  method: 'POST',\n  body: formData,\n}).then(res => res.json()).then(res => {\n  console.log(res, '12>>>>>>>>>>>>>');\n  for (const item of context.context.children) {\n    if (item.rendererType == 'form') {\n      item.component.props.store.setValueByName(event.data.field, res.data.url)\n      doAction([\n        {\n          \"componentId\": \"u:f4314e6c6fe9\",\n          \"ignoreError\": false,\n          \"outputVar\": \"submitResult\",\n          \"actionType\": \"submit\"\n        }\n      ])\n    }\n  }\n})\n\n\n"
              }
            ]
          }
        }
      }
    ],
    'size': 'xs',
    'style': {
      'position': 'static',
      'display': 'block',
      'flex': '0 0 150px',
      'flexBasis': '310px',
      'overflowX': 'hidden',
      'overflowY': 'hidden',
      'height': '620px'
    },
    'wrapperBody': false,
    'isFixedHeight': true,
    'isFixedWidth': false,
    'id': 'u:006c8e488f6d',
    'wrapperCustomStyle': {
      'root': {
        'background': "url('http://47.92.37.224:7076/pic/mobile-bg.png') left top/310px auto no-repeat",
        'text-align': 'center'
      }
    }
  };
  previewSchema = {
    'type': 'container',
    'body': [
      {
        'type': 'iframe',
        'src': 'http://192.168.1.239:8080/#/pages/tabBar/my/my',
        'id': 'u:f13967954d07',
        'width': '268px',
        'height': '95%',
        'wrapperCustomStyle': {},
        'themeCss': {
          'baseControlClassName': {
            'padding-and-margin:default': {
              'marginTop': '5%'
            },
            'radius:default': {
              'top-left-border-radius': '26px',
              'top-right-border-radius': '26px',
              'bottom-left-border-radius': '26px',
              'bottom-right-border-radius': '26px'
            }
          }
        },
        'onEvent': {
          'detail': {
            'actions': [
              {
                'actionType': 'custom',
                'script': "/* 自定义JS使用说明：\n  * 1.动作执行函数doAction，可以执行所有类型的动作\n  * 2.通过上下文对象context可以获取当前组件实例，例如context.props可以获取该组件相关属性\n  * 3.事件对象event，在doAction之后执行event.stopPropagation();可以阻止后续动作执行\n*/\nconsole.log(context, 'context')\nconsole.log(event.data)\nconsole.log(window, 'window')\nvar formData = new FormData();\nvar file = new File([event.data.file], new Date().getTime()+'.jpg', {type: 'image/jpg'})\nformData.append('file', file);\n\nfetch('http://47.92.37.224:7078/admin-api/common/local/file/amisUpload', {\n  method: 'POST',\n  body: formData,\n}).then(res => res.json()).then(res => {\n  console.log(res, '12>>>>>>>>>>>>>');\n  for (const item of context.context.children) {\n    if (item.rendererType == 'form') {\n      item.component.props.store.setValueByName(event.data.field, res.data.url)\n      doAction([\n        {\n          \"componentId\": \"u:f4314e6c6fe9\",\n          \"ignoreError\": false,\n          \"outputVar\": \"submitResult\",\n          \"actionType\": \"submit\"\n        }\n      ])\n    }\n  }\n})\n\n\n"
              }
            ]
          }
        }
      }
    ],
    'size': 'xs',
    'style': {
      'position': 'static',
      'display': 'block',
      'flex': '0 0 150px',
      'flexBasis': '310px',
      'overflowX': 'hidden',
      'overflowY': 'hidden',
      'height': '620px'
    },
    'wrapperBody': false,
    'isFixedHeight': true,
    'isFixedWidth': false,
    'id': 'u:006c8e488f6d',
    'wrapperCustomStyle': {
      'root': {
        'background': "url('http://47.92.37.224:7076/pic/mobile-bg.png') left top/310px auto no-repeat",
        'text-align': 'center'
      }
    }
  };

  // 容器类组件必需字段
  // regions = [
  //   {
  //     key: 'body',
  //     label: '内容区'
  //   }
  // ];

  panelTitle = '配置'; // 右侧属性面板Title

  panelControls = [ // 右侧属性面板配置项

  ];
}

registerEditorPlugin(InfoGridPlugin)
