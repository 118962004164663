/* eslint-disable no-template-curly-in-string */
/**
 * @file 编辑器扩展, 增加自定义组件
 */
import { BasePlugin, registerEditorPlugin } from 'amis-editor'

export class InfoGridPlugin extends BasePlugin {
  // 关联渲染器名字
  rendererName = 'cham-crud';
  $schema = '/schemas/UnkownSchema.json';
  notRenderFormZone = true;
  // 组件名称（组件面板显示的Title）
  name = 'chamcrud';
  order = 0; // 排序
  description = '自定义crud';
  tags = ['数据容器'];
  icon = 'fa fa-file-code-o';
  scaffold = {
    'type': 'crud',
    'id': 'AlarmUser:table',
    'draggable': false,
    'api': {
      'method': 'get',
      'url': '/system/user/page',
      'messages': {},
      'requestAdaptor': '',
      'adaptor': '',
      'dataType': 'json'
    },
    'perPage': 10,
    'keepItemSelectionOnPageChange': false,
    'maxKeepItemSelectionLength': 11,
    'labelTpl': '${id}',
    'autoGenerateFilter': {
      'columnsNum': 5,
      'showBtnToolbar': false
    },
    'quickSaveApi': '',
    'quickSaveItemApi': '',
    'filterTogglable': true,
    'headerToolbar': [
      {
        'type': 'button',
        'label': '更多',
        'tpl': '内容',
        'wrapperComponent': '',
        'id': 'u:53d34ac9b909',
        'align': 'left',
        'primary': true,
        'onEvent': {
          'click': {
            'weight': 0,
            'actions': [
              {
                'ignoreError': false,
                'actionType': 'custom',
                'script': "/* 自定义JS使用说明：\n  * 1.动作执行函数doAction，可以执行所有类型的动作\n  * 2.通过上下文对象context可以获取当前组件实例，例如context.props可以获取该组件相关属性\n  * 3.事件对象event，在doAction之后执行event.stopPropagation();可以阻止后续动作执行\n*/\nconst myMsg = '我是自定义JS';\n\nstoreId = context.props.store.id\nconsole.log(\"CRUD Store ID----》\", storeId)\nconsole.log(\"TABLE Store\", context.props.rootStore)\n\nObject.keys(context.props.rootStore.stores).forEach(key => {\n  if (context.props.rootStore.stores[key].parentId === storeId && context.props.rootStore.stores[key].storeType=== \"TableStore\")\n  {\n    context.props.rootStore.stores[key].toggleSearchFormExpanded();\n  }\n});\n"
              }
            ]
          }
        },
        'className': 'zzcybtn zzcybtn-primary',
        'wrapperCustomStyle': {
          'root': {
            'background': 'rgb(40, 120, 255) !important'
          }
        }
      },
      {
        'type': 'button',
        'id': 'AlarmUser:search',
        'label': '查询',
        'primary': true,
        'onEvent': {
          'click': {
            'weight': 0,
            'actions': [
              {
                'ignoreError': false,
                'actionType': 'submit',
                'componentId': 'AlarmUser:table_SearchForm',
                'outputVar': 'submitResult'
              }
            ]
          }
        },
        'className': 'zzcybtn zzcybtn-primary'
      },
      {
        'type': 'button',
        'label': '重置',
        'id': 'AlarmUser:reset',
        'onEvent': {
          'click': {
            'weight': 0,
            'actions': [
              {
                'componentId': 'AlarmUser:table_SearchForm',
                'ignoreError': false,
                'actionType': 'reset'
              }
            ]
          }
        },
        'className': 'zzcybtn zzcybtn-info'
      },
      {
        'type': 'button',
        'label': '导出',
        'id': 'AlarmUser:export',
        'onEvent': {
          'click': {
            'weight': 0,
            'actions': [
              {
                'ignoreError': false,
                'actionType': 'download',
                'api': {
                  'url': '/share-work/share-work/export',
                  'method': 'post',
                  'requestAdaptor': '',
                  'adaptor': '',
                  'messages': {},
                  'data': {
                    '&': '$$'
                  },
                  'responseType': 'blob'
                }
              }
            ]
          }
        },
        'className': 'zzcybtn zzcybtn-primary',
        'level': 'primary'
      },
      {
        'type': 'bulkActions'
      },
      {
        'type': 'columns-toggler',
        'align': 'right'
      },
      {
        'type': 'drag-toggler',
        'align': 'right'
      }
    ],
    'footerToolbar': [
      {
        'type': 'statistics'
      },
      {
        'type': 'pagination',
        'layout': 'perPage,pager,go'
      }
    ],
    'bulkActions': [
      {
        'type': 'button',
        'label': '批量删除',
        'id': 'AlarmUser:delete',
        'className': 'zzcybtn zzcybtn-info',
        'confirmText': '确定要批量删除数据?',
        'onEvent': {
          'click': {
            'weight': 0,
            'actions': [
              {
                'ignoreError': false,
                'outputVar': 'responseResult',
                'actionType': 'ajax',
                'options': {},
                'api': {
                  'url': '/biz/app-feedback-type/delete',
                  'method': 'post',
                  'requestAdaptor': "api.data=api.data.ids.split(',');\r\nreturn api;",
                  'adaptor': '',
                  'messages': {},
                  'data': {
                    '&': '$$'
                  }
                }
              },
              {
                'componentId': 'u:916e3176e27b',
                'ignoreError': false,
                'actionType': 'reload'
              },
              {
                'componentId': 'AlarmUser:table',
                'ignoreError': false,
                'actionType': 'reload',
                'args': {
                  'resetPage': true
                }
              }
            ]
          }
        }
      }
    ],
    'columns': [
      {
        'type': 'text',
        'name': 'typeName',
        'label': '类型名称',
        'id': 'u:867f38c1cd1d',
        'searchable': {
          'clearable': 'true',
          'id': 'u:77f16230a026',
          'name': 'typeName',
          'type': 'input-text',
          'placeholder': '请输入',
          'mode': 'normal'
        }
      },
      {
        'label': '设备形态',
        'type': 'mapping',
        'name': 'userName',
        'id': 'u:ae1e6050fb16',
        'searchable': {
          'clearable': 'true',
          'id': 'u:917c3830eb4b',
          'name': 'userName',
          'type': 'select',
          'options': [
            {
              'label': '直连',
              'value': 0
            },
            {
              'label': '分体柜机',
              'value': 1
            },
            {
              'label': '分体格口',
              'value': 2
            }
          ],
          'multiple': false,
          'mode': 'normal'
        },
        'map': {
          '0': '直连',
          '1': '分体柜机',
          '2': '分体格口',
          '*': '直连'
        }
      },
      {
        'label': '通讯类型',
        'type': 'mapping',
        'name': 'nickName',
        'id': 'u:952f0fd7dbba',
        'map': {
          '0': "<span class='label label-info'>蓝牙</span>",
          '1': '蓝牙+wifi',
          '2': '4g/nb/网线',
          '*': "<span class='label label-info'>蓝牙</span>"
        },
        'searchable': {
          'clearable': 'true',
          'name': 'nickName',
          'type': 'select',
          'options': [
            {
              'value': '0',
              'label': '蓝牙'
            },
            {
              'value': '1',
              'label': '蓝牙+wifi'
            },
            {
              'value': '2',
              'label': '4g/nb/网线'
            }
          ],
          'multiple': false,
          'mode': 'normal',
          'id': 'u:7837808b6a0f'
        }
      },
      {
        'label': '设备型号',
        'type': 'text',
        'name': 'email',
        'id': 'u:20013e0a7be6',
        'searchable': {
          'clearable': 'true',
          'id': 'u:a8e6672e0847',
          'name': 'email',
          'type': 'input-text',
          'placeholder': '请输入',
          'mode': 'normal'
        }
      },
      {
        'type': 'datetime',
        'format': 'YYYY-MM-DD HH:mm:ss',
        'value': 1707034934,
        'name': 'createTime',
        'label': '创建时间',
        'id': 'u:3add222ca9e0',
        'searchable': {
          'clearable': 'true',
          'name': 'startTime',
          'extraName': 'endTime',
          'type': 'input-date-range',
          'mode': 'normal',
          'label': '日期范围',
          'id': 'u:39644577a75e'
        }
      },
      {
        'type': 'operation',
        'label': '操作',
        'fixed': 'right',
        'width': 143,
        'buttons': [
          {
            'type': 'button',
            'label': '详情',
            'onEvent': {
              'click': {
                'actions': [
                  {
                    'ignoreError': false,
                    'actionType': 'drawer',
                    'args': {
                      'fromCurrentDialog': true
                    },
                    'drawer': {
                      '$ref': 'drawer-ref-3'
                    }
                  }
                ]
              }
            },
            'id': 'u:42d90d0721c1',
            'className': 'zzcybtn zzcybtn-primary',
            'level': 'primary',
            'size': 'md'
          },
          {
            'type': 'button',
            'label': '删除',
            'onEvent': {
              'click': {
                'actions': [
                  {
                    'ignoreError': false,
                    'outputVar': 'responseResult',
                    'actionType': 'ajax',
                    'options': {},
                    'api': {
                      'url': '/gather/device/alarm/delete',
                      'method': 'get'
                    }
                  }
                ]
              }
            },
            'id': 'u:e639b721ba90',
            'level': 'danger',
            'size': 'md',
            'className': 'zzcybtn zzcybtn-danger',
            'confirmText': '数据删除后无法恢复，请确认!'
          }
        ],
        'id': 'u:6df636742b5a'
      }
    ],
    'messages': {},
    'filterSettingSource': [
      'id',
      'deptId',
      'userName',
      'nickName',
      'userType',
      'email',
      'phoneNumber',
      'sex',
      'avatar',
      'password',
      'loginIp',
      'loginDate',
      'status',
      'createTime',
      'remark',
      'dept',
      'roles'
    ],
    'features': [
      'filter'
    ],
    'itemActions': [],
    'onEvent': {},
    'footable': false,
    'alwaysShowPagination': true,
    'name': '报警联系人',
    'autoFillHeight': true,
    'bodyClassName': 'zzcy-table',
    'className': 'zzcy-crud',
    'perPageAvailable': [
      10,
      20,
      50,
      100
    ],
    'perPageField': 'pageSize',
    'pageField': 'pageNum',
    'syncLocation': false
  };
  previewSchema = {
    'type': 'crud',
    'id': 'AlarmUser:table',
    'draggable': false,
    'api': {
      'method': 'get',
      'url': '/system/user/page',
      'messages': {},
      'requestAdaptor': '',
      'adaptor': '',
      'dataType': 'json'
    },
    'perPage': 10,
    'keepItemSelectionOnPageChange': false,
    'maxKeepItemSelectionLength': 11,
    'labelTpl': '${id}',
    'autoGenerateFilter': {
      'columnsNum': 5,
      'showBtnToolbar': false
    },
    'quickSaveApi': '',
    'quickSaveItemApi': '',
    'filterTogglable': true,
    'headerToolbar': [
      {
        'type': 'button',
        'tpl': '内容',
        'wrapperComponent': '',
        'id': 'u:29d52e7fdbfc',
        'label': '更多',
        'align': 'left',
        'primary': true,
        'onEvent': {
          'click': {
            'weight': 0,
            'actions': [
              {
                'ignoreError': false,
                'actionType': 'custom',
                'script': "/* 自定义JS使用说明：\n  * 1.动作执行函数doAction，可以执行所有类型的动作\n  * 2.通过上下文对象context可以获取当前组件实例，例如context.props可以获取该组件相关属性\n  * 3.事件对象event，在doAction之后执行event.stopPropagation();可以阻止后续动作执行\n*/\nconst myMsg = '我是自定义JS';\n\nstoreId = context.props.store.id\nconsole.log(\"CRUD Store ID----》\", storeId)\nconsole.log(\"TABLE Store\", context.props.rootStore)\n\nObject.keys(context.props.rootStore.stores).forEach(key => {\n  if (context.props.rootStore.stores[key].parentId === storeId && context.props.rootStore.stores[key].storeType=== \"TableStore\")\n  {\n    context.props.rootStore.stores[key].toggleSearchFormExpanded();\n  }\n});\n"
              }
            ]
          }
        },
        'className': 'zzcybtn zzcybtn-primary'
      },
      {
        'type': 'button',
        'label': '查询',
        'id': 'AlarmUser:search',
        'primary': true,
        'onEvent': {
          'click': {
            'weight': 0,
            'actions': [
              {
                'componentId': 'AlarmUser:table_SearchForm',
                'ignoreError': false,
                'outputVar': 'submitResult',
                'actionType': 'submit'
              }
            ]
          }
        },
        'className': 'zzcybtn zzcybtn-primary'
      },
      {
        'type': 'button',
        'label': '重置',
        'id': 'AlarmUser:reset',
        'onEvent': {
          'click': {
            'weight': 0,
            'actions': [
              {
                'componentId': 'AlarmUser:table_SearchForm',
                'ignoreError': false,
                'actionType': 'reset'
              }
            ]
          }
        },
        'className': 'zzcybtn zzcybtn-info'
      },
      {
        'type': 'button',
        'label': '导出',
        'id': 'AlarmUser:export',
        'className': 'zzcybtn zzcybtn-info'
      },
      {
        'type': 'columns-toggler',
        'align': 'right'
      },
      {
        'type': 'drag-toggler',
        'align': 'right'
      }
    ],
    'footerToolbar': [
      {
        'type': 'statistics'
      },
      {
        'type': 'pagination',
        'layout': 'perPage,pager,go'
      }
    ],
    'columns': [
      {
        'type': 'text',
        'name': 'typeName',
        'label': '类型名称',
        'id': 'u:35ef80ae8cb6',
        'searchable': {
          'clearable': 'true',
          'id': 'u:6704bbac3dc9',
          'name': 'typeName',
          'type': 'input-text',
          'placeholder': '请输入',
          'mode': 'normal'
        }
      },
      {
        'label': '设备形态',
        'type': 'mapping',
        'name': 'userName',
        'id': 'u:bb6c0f4995e3',
        'searchable': {
          'clearable': 'true',
          'id': 'u:b0e29c887df9',
          'name': 'userName',
          'type': 'select',
          'options': [
            {
              'label': '直连',
              'value': 0
            },
            {
              'label': '分体柜机',
              'value': 1
            },
            {
              'label': '分体格口',
              'value': 2
            }
          ],
          'multiple': false,
          'mode': 'normal'
        },
        'map': {
          '0': '直连',
          '1': '分体柜机',
          '2': '分体格口',
          '*': '直连'
        }
      },
      {
        'label': '通讯类型',
        'type': 'mapping',
        'name': 'nickName',
        'id': 'u:c952a52b88aa',
        'map': {
          '0': '纯蓝牙',
          '1': '蓝牙+wifi',
          '2': '4g/nb/网线',
          '*': '纯蓝牙'
        },
        'searchable': {
          'clearable': 'true',
          'id': 'u:c89043d00a71',
          'name': 'nickName',
          'type': 'select',
          'options': [
            {
              'label': '纯蓝牙',
              'value': 0
            },
            {
              'label': '蓝牙+wifi',
              'value': 1
            },
            {
              'label': '4g/nb/网线',
              'value': 2
            }
          ],
          'multiple': false,
          'mode': 'normal'
        }
      },
      {
        'label': '设备型号',
        'type': 'text',
        'name': 'email',
        'id': 'u:ca4e5babf68e',
        'searchable': {
          'clearable': 'true',
          'id': 'u:12218cd0fc55',
          'name': 'email',
          'type': 'input-text',
          'placeholder': '请输入',
          'mode': 'normal'
        }
      },
      {
        'type': 'datetime',
        'format': 'YYYY-MM-DD HH:mm:ss',
        'value': 1707034934,
        'name': 'createTime',
        'label': '创建时间',
        'id': 'u:823a0642e326',
        'searchable': {
          'clearable': 'true',
          'name': 'startTime',
          'extraName': 'endTime',
          'type': 'input-date-range',
          'mode': 'normal',
          'label': '日期范围',
          'id': 'u:a76078d93418'
        }
      },
      {
        'type': 'operation',
        'label': '操作',
        'fixed': 'right',
        'width': 143,
        'buttons': [
          {
            'type': 'button',
            'label': '详情',
            'onEvent': {
              'click': {
                'actions': [
                  {
                    'ignoreError': false,
                    'actionType': 'drawer',
                    'args': {
                      'fromCurrentDialog': true
                    },
                    'drawer': {
                      '$ref': 'drawer-ref-3'
                    }
                  }
                ]
              }
            },
            'id': 'u:2959bc6e7694',
            'className': 'zzcybtn zzcybtn-primary',
            'level': 'primary',
            'size': 'md'
          },
          {
            'type': 'button',
            'label': '删除',
            'onEvent': {
              'click': {
                'actions': [
                  {
                    'ignoreError': false,
                    'outputVar': 'responseResult',
                    'actionType': 'ajax',
                    'options': {},
                    'api': {
                      'url': '/gather/device/alarm/delete',
                      'method': 'get'
                    }
                  }
                ]
              }
            },
            'id': 'u:f3e731cdc43c',
            'level': 'danger',
            'size': 'md',
            'className': 'zzcybtn zzcybtn-danger',
            'confirmText': '数据删除后无法恢复，请确认!'
          }
        ],
        'id': 'u:2d8eddc265d5'
      }
    ],
    'perPageAvailable': [
      10,
      20,
      50,
      100
    ],
    'messages': {},
    'filterSettingSource': [
      'id',
      'deptId',
      'userName',
      'nickName',
      'userType',
      'email',
      'phoneNumber',
      'sex',
      'avatar',
      'password',
      'loginIp',
      'loginDate',
      'status',
      'createTime',
      'remark',
      'dept',
      'roles'
    ],
    'perPageField': 'pageSize',
    'pageField': 'pageNum',
    'features': [
      'filter'
    ],
    'itemActions': [],
    'onEvent': {},
    'footable': false,
    'alwaysShowPagination': true,
    'name': '报警联系人',
    'autoFillHeight': true,
    'bodyClassName': 'zzcy-table',
    'syncLocation': false

  };

  // 容器类组件必需字段
  // regions = [
  //   {
  //     key: 'body',
  //     label: '内容区'
  //   }
  // ];

  panelTitle = '配置'; // 右侧属性面板Title

  panelControls = [ // 右侧属性面板配置项

  ];
  buildEditorContextMenu = function (content, menus) {
    console.log(this, content)
    console.log('buildEditorContextMenu>>>>>', menus)
    const schema = this.manager.store.getSchema(content.id) // 获取当前组件id的schema
    console.log(schema, '463>>>>>>>>')

    if (this.name === 'chamcrud' && content.schemaPath.includes('columns')) {
      if (schema.type === 'operation') {

      } else {
        var textFun = () => {
          console.log('textFun')
          return {
            'clearable': 'true',
            'name': schema.name,
            'type': 'input-text',
            'placeholder': '请输入',
            'mode': 'normal'
          }
        }
        var mappingFun = () => {
          if (schema.map) {
            return {
              'clearable': 'true',
              'name': schema.name,
              'type': 'select',
              'options': Object.keys(schema.map).filter(item => !(item === '$$id' || item === '*')).map((key) => {
                const matchArr = schema.map[key].match(/(?<=>).*(?=<\/)/g)
                return ({ value: key, label: matchArr ? matchArr[0] : schema.map[key] })
              }),
              'multiple': false,
              'mode': 'normal'
            }
          } else if (schema.source) {
            return {
              'clearable': 'true',
              'name': schema.name,
              'type': 'select',
              'source': schema.source,
              'labelField': schema.labelField,
              'valueField': schema.valueField,
              'multiple': false,
              'mode': 'normal'
            }
          } else {
            return {
              'clearable': 'true',
              'name': schema.name,
              'type': 'input-text',
              'placeholder': '请输入',
              'mode': 'normal'
            }
          }
        }
        var dateTimeFun = () => {
          return {
            'clearable': 'true',
            'name': schema.name == 'createTime' ? 'startTime' : schema.name + 'Start',
            'extraName': schema.name == 'createTime' ? 'endTime' : schema.name + 'End',
            'type': 'input-date-range',
            'mode': 'normal',
            'label': schema.label
          }
        }
        var maps = new Map([
          ['text', textFun],
          ['tpl', textFun],
          ['mapping', mappingFun],
          ['datetime', dateTimeFun],
          ['date', dateTimeFun]
        ])
        menus.unshift({
          disabled: false,
          label: '加入搜索',
          onSelect: () => {
            console.log('选择')
            this.manager.store.replaceChild(content.id, {
              ...schema,
              'searchable': maps.get(schema.type)()
            })
          }
        })
      }
    } else if (schema.type === 'crud') {
      const textFun = (schemaItem) => {
        return {
          'type': 'input-text',
          'label': schemaItem.label,
          'name': schemaItem.name,
          'placeholder': '请输入' + schemaItem.label
        }
      }
      const mappingFun = (schemaItem) => {
        if (schemaItem.map) {
          return {
            'type': 'select',
            'clearable': 'true',
            'label': schemaItem.label,
            'name': schemaItem.name,
            'options': Object.keys(schemaItem.map).filter(item => !(item === '$$id' || item === '*')).map((key) => {
              const matchArr = schemaItem.map[key].match(/(?<=>).*(?=<\/)/g)
              return ({ value: key, label: matchArr ? matchArr[0] : schemaItem.map[key] })
            }),
            'multiple': false,
            'value': '',
            'placeholder': '请选择' + schemaItem.label
          }
        } else if (schemaItem.source) {
          return {
            'type': 'select',
            'clearable': 'true',
            'label': schemaItem.label,
            'name': schemaItem.name,
            'source': schemaItem.source,
            'labelField': schemaItem.labelField,
            'valueField': schemaItem.valueField,
            'multiple': false,
            'value': '',
            'placeholder': '请选择' + schemaItem.label
          }
        } else {
          return {
            'type': 'input-text',
            'label': schemaItem.label,
            'name': schemaItem.name,
            'placeholder': '请输入' + schemaItem.label
          }
        }
      }
      const dateTimeFun = (schema) => {
        return {
          'clearable': 'true',
          'name': schema.name == 'createTime' ? 'startTime' : schema.name + 'Start',
          'extraName': schema.name == 'createTime' ? 'endTime' : schema.name + 'End',
          'type': 'input-date-range',
          'label': schema.label,
          'placeholder': '请选择' + schema.label
        }
      }
      const mapsAdd = new Map([
        ['text', textFun],
        ['tpl', textFun],
        ['mapping', mappingFun],
        ['datetime', textFun],
        ['date', textFun]
      ])
      menus.unshift({
        disabled: false,
        label: '添加详情',
        onSelect: () => {
          const formBody = []
          schema.columns.forEach(schemaItem => {
            if (schemaItem.type !== 'operation') {
              if (['text', 'tpl', 'mapping', 'datetime', 'date'].includes(schemaItem.type)) {
                formBody.push(mapsAdd.get(schemaItem.type)(schemaItem))
              } else {
                formBody.push({
                  'type': 'input-text',
                  'label': schemaItem.label,
                  'name': schemaItem.name,
                  'placeholder': '请输入' + schemaItem.label
                })
              }
            }
          })

          const formSchema = {
            'type': 'form',
            'title': '表单',
            'mode': 'horizontal',
            'dsType': 'api',
            'feat': 'Edit',
            'body': formBody,
            'actions': [],
            'resetAfterSubmit': true,
            'onEvent': {
              'submitSucc': {
                'weight': 0,
                'actions': [
                  {
                    'componentId': 'AlarmUser:table',
                    'ignoreError': false,
                    'actionType': 'reload',
                    'data': {},
                    'dataMergeMode': 'override',
                    'args': {
                      'resetPage': true
                    }
                  }
                ]
              }
            },
            'initApi': {
              'method': 'get',
              'url': '/detail?id=${id}',
              'requestAdaptor': '',
              'adaptor': '',
              'messages': {}
            },
            'static': true
          }
          var detailFormFun = () => {
            return {
              'type': 'button',
              'label': '详情',
              'id': 'AlarmUser:detail',
              'onEvent': {
                'click': {
                  'weight': 0,
                  'actions': [
                    {
                      'ignoreError': false,
                      'actionType': 'drawer',
                      'drawer': {
                        'type': 'drawer',
                        'title': '详情',
                        'body': [
                          formSchema
                        ],
                        'className': 'app-popover :AMISCSSWrapper',
                        'actions': [
                        ],
                        'closeOnOutside': true,
                        'closeOnEsc': true
                      }
                    }
                  ]
                }
              },
              'className': 'zzcybtn zzcybtn-primary',
              'level': 'primary'
            }
          }
          let isEdit = false
          schema.columns.forEach(columnsItem => {
            if (columnsItem.type === 'operation') {
              columnsItem.buttons.forEach(item => {
                if (item.id === 'AlarmUser:detail') {
                  isEdit = true
                  item = {
                    ...item,
                    ...detailFormFun()
                  }
                }
              })
              if (!isEdit) {
                columnsItem.buttons.unshift(detailFormFun())
              }
            }
          })

          console.log('添加详情>>>>>', schema)
          this.manager.store.replaceChild(content.id, {
            ...schema
          })
        }
      })
      menus.unshift({
        disabled: false,
        label: '添加编辑表单',
        onSelect: () => {
          const formBody = []
          let isAddId = false
          schema.columns.forEach(schemaItem => {
            if (schemaItem.type !== 'operation') {
              if (schemaItem.name === 'id') {
                isAddId = true
              }
              if (['text', 'tpl', 'mapping', 'datetime', 'date'].includes(schemaItem.type)) {
                formBody.push(mapsAdd.get(schemaItem.type)(schemaItem))
              } else {
                formBody.push({
                  'type': 'input-text',
                  'label': schemaItem.label,
                  'name': schemaItem.name,
                  'placeholder': '请输入' + schemaItem.label
                })
              }
            }
          })

          if (!isAddId) {
            // 如果没有生成id
            formBody.unshift({
              'type': 'input-text',
              'label': 'id',
              'name': 'id',
              'hidden': true
            })
          }

          const formSchema = {
            'type': 'form',
            'title': '表单',
            'mode': 'horizontal',
            'dsType': 'api',
            'feat': 'Edit',
            'body': formBody,
            'actions': [],
            'resetAfterSubmit': true,
            'onEvent': {
              'submitSucc': {
                'weight': 0,
                'actions': [
                  {
                    'componentId': 'AlarmUser:table',
                    'ignoreError': false,
                    'actionType': 'reload',
                    'data': {},
                    'dataMergeMode': 'override',
                    'args': {
                      'resetPage': true
                    }
                  }
                ]
              }
            },
            'initApi': {
              'method': 'get',
              'url': '/detail?id=${id}',
              'requestAdaptor': '',
              'adaptor': '',
              'messages': {}
            },
            'api': {
              'url': '/edit',
              'method': 'post',
              'requestAdaptor': '',
              'adaptor': 'return response',
              'messages': {},
              'dataType': 'json',
              'data': {
                '&': '$$'
              }
            }
          }
          var editFormFun = () => {
            return {
              'type': 'button',
              'label': '编辑',
              'id': 'AlarmUser:edit',
              'onEvent': {
                'click': {
                  'weight': 0,
                  'actions': [
                    {
                      'ignoreError': false,
                      'actionType': 'drawer',
                      'drawer': {
                        'type': 'drawer',
                        'title': '编辑',
                        'body': [
                          formSchema
                        ],
                        'className': 'app-popover :AMISCSSWrapper',
                        'actions': [
                          {
                            'type': 'button',
                            'actionType': 'cancel',
                            'label': '取消'
                          },
                          {
                            'type': 'button',
                            'actionType': 'confirm',
                            'label': '确认',
                            'primary': true
                          }
                        ],
                        'closeOnOutside': true,
                        'closeOnEsc': true
                      }
                    }
                  ]
                }
              },
              'className': 'zzcybtn zzcybtn-primary',
              'level': 'primary'
            }
          }
          let isEdit = false
          schema.columns.forEach(columnsItem => {
            if (columnsItem.type === 'operation') {
              columnsItem.buttons.forEach(item => {
                if (item.id === 'AlarmUser:edit') {
                  isEdit = true
                  item = {
                    ...item,
                    ...editFormFun()
                  }
                }
              })
              if (!isEdit) {
                columnsItem.buttons.unshift(editFormFun())
              }
            }
          })

          console.log('添加编辑表单>>>>>', schema)
          this.manager.store.replaceChild(content.id, {
            ...schema
          })
        }
      })

      menus.unshift({
        disabled: false,
        label: '添加新增表单',
        onSelect: () => {
          const formBody = []

          schema.columns.forEach(schemaItem => {
            if (schemaItem.type !== 'operation') {
              if (['text', 'tpl', 'mapping', 'datetime', 'date'].includes(schemaItem.type)) {
                formBody.push(mapsAdd.get(schemaItem.type)(schemaItem))
              } else {
                formBody.push({
                  'type': 'input-text',
                  'label': schemaItem.label,
                  'name': schemaItem.name,
                  'placeholder': '请输入' + schemaItem.label
                })
              }
            }
          })

          const formSchema = {
            'type': 'form',
            'title': '表单',
            'mode': 'horizontal',
            'dsType': 'api',
            'feat': 'Insert',
            'body': formBody,
            'actions': [],
            'resetAfterSubmit': true,
            'onEvent': {
              'submitSucc': {
                'weight': 0,
                'actions': [
                  {
                    'componentId': 'AlarmUser:table',
                    'ignoreError': false,
                    'actionType': 'reload',
                    'data': {},
                    'dataMergeMode': 'override',
                    'args': {
                      'resetPage': true
                    }
                  }
                ]
              }
            },
            'api': {
              'url': '/add',
              'method': 'post',
              'requestAdaptor': '',
              'adaptor': 'retrun response',
              'messages': {},
              'dataType': 'json',
              'data': {
                '&': '$$'
              }
            }
          }

          var addFormFun = () => {
            return {
              'type': 'button',
              'label': '新增',
              'id': 'AlarmUser:add',
              'onEvent': {
                'click': {
                  'weight': 0,
                  'actions': [
                    {
                      'ignoreError': false,
                      'actionType': 'drawer',
                      'drawer': {
                        'type': 'drawer',
                        'title': '新增',
                        'body': [
                          formSchema
                        ],
                        'className': 'app-popover :AMISCSSWrapper',
                        'actions': [
                          {
                            'type': 'button',
                            'actionType': 'cancel',
                            'label': '取消'
                          },
                          {
                            'type': 'button',
                            'actionType': 'confirm',
                            'label': '确认',
                            'primary': true
                          }
                        ],
                        'closeOnOutside': true,
                        'closeOnEsc': true
                      }
                    }
                  ]
                }
              },
              'className': 'zzcybtn zzcybtn-primary',
              'level': 'primary'
            }
          }
          let isAdd = false

          schema.headerToolbar.forEach(item => {
            if (item.id === 'AlarmUser:add') {
              isAdd = true
              item = {
                ...item,
                ...addFormFun()
              }
            }
          })
          if (!isAdd) {
            const headerToolbar1 = []
            const headerToolbar2 = []
            schema.headerToolbar.forEach((item, index) => {
              if (index < 3) {
                headerToolbar1.push(item)
              } else {
                headerToolbar2.push(item)
              }
            })
            headerToolbar1.push(addFormFun())

            schema.headerToolbar = headerToolbar1.concat(headerToolbar2)
          }
          console.log('添加新增表单>>>>>', schema)
          this.manager.store.replaceChild(content.id, {
            ...schema
          })
        }
      })
    }
  }
}

registerEditorPlugin(InfoGridPlugin)
