<template>
  <div class="box" ref="renderBox"></div>
</template>

<script>
// import 'font-awesome/css/font-awesome.css'
// import 'bootstrap/dist/css/bootstrap.css'
// // import 'amis-editor/dist/style.css'
// import 'amis-editor-core/lib/style.css'
// // import 'amis-theme-editor/lib/style.css'
// // import 'amis-theme-editor/lib/renderers.css'
// import 'amis-ui/lib/helper.css'
// // import 'amis-ui/scss/helper.scss'
// // import 'amis-ui/scss/helper.scss'
// import 'amis-ui/lib/index.js'
// import 'amis-ui/lib/locale/en-US'

// import 'amis/lib/helper.css'
// import 'amis/sdk/iconfont.css'
// import 'amis/lib/themes/antd.css'
// import 'amis/lib/themes/default.css'
// import 'amis/lib/themes/dark.css'
// import 'amis/lib/themes/cxd.css'
// import 'amis/sdk/sdk.js'
import { render as renderSchema,
    // toast,
    alert,
    confirm
} from 'amis'
import copy from 'copy-to-clipboard'
import ReactDOM from 'react-dom'
import * as qs from 'qs'
import store from '@/store'

// import storage from 'store'
// import { ACCESS_TOKEN } from '@/store/mutation-types'
import axios from 'axios'
import request from '@/utils/requestAmis'
import { mapGetters } from 'vuex'
import en from './en'
import zh from './zh'

export default {
    props: {
        // eslint-disable-next-line vue/require-default-prop
        schema: {
            type: Object
        },
        // eslint-disable-next-line vue/require-default-prop
        updateLocation: {
            type: Function
        },
        // eslint-disable-next-line vue/require-default-prop
        onAction: {
            type: Function
        }
    },
    data () {
        return {
            theme: 'antd',
            en,
            zh
        }
    },
    computed: {
        ...mapGetters(['lang'])
    },
    mounted() {
        this.initEnv()

        console.log('initEnv>>>>>', this.lang)
        // console.log('schema>>>>', this.schema)
        // const root = ReactDOM.createRoot(document.getElementById('root'))
        ReactDOM.render(renderSchema(this.schema, {
            onAction: this.onAction || this.handleAction,
            theme: this.theme,
            locale: this.lang
        }, this.env), this.$refs.renderBox)
    },

    methods: {
        initEnv () {
            const _vm = this
            let replaceTextKeyValue = {
                // '经度': 'JINGDU',
                // '新增': 'Add',
                // '总项目数量': 'zongxiangm'
            }
            const lang = store.getters.lang
            if (lang.indexOf('zh') !== -1) {
                replaceTextKeyValue = this.zh
            } else if (lang.indexOf('en') !== -1) {
                replaceTextKeyValue = this.en
            }
            this.env = {

                enableAMISDebug: false,
                session: 'global',
                updateLocation: this.updateLocation || this.updateRoute,
                // replaceText: JSON.stringify(this.schema).indexOf('"chart"') !== -1 ? undefined : replaceTextKeyValue,
                replaceText: replaceTextKeyValue,
                isCurrentUrl: (to) => {
                    const link = this.normalizeLink(to)
                    const location = window.location
                    let pathname = link
                    let search = ''
                    const idx = link.indexOf('?')
                    if (~idx) {
                        pathname = link.substring(0, idx)
                        search = link.substring(idx)
                    }
                    if (search) {
                        if (pathname !== location.pathname || !location.search) {
                            return false
                        }
                        const query = qs.parse(search.substring(1))
                        const currentQuery = qs.parse(location.search.substring(1))
                        return Object.keys(query).every(key => query[key] === currentQuery[key])
                    } else if (pathname === location.pathname) {
                        return true
                    }
                    return false
                },
                fetcher: ({
                    url,
                    method,
                    data,
                    responseType,
                    config,
                    headers
                }) => {
                    // responseType: 'blob'
                    console.log('method>>>>', method)
                    console.log('config>>>>', config)
                    console.log('data>>>>', data)
                    config = config || {}
                    config.withCredentials = false
                    responseType && (config.responseType = responseType)
                    config.method = method
                    const requestObj = {
                        url: url,
                        method: method,
                        headers: headers,
                        ...config
                    }
                    console.log('requestObj>>>', requestObj)

                    if (method === 'get' && data) {
                        requestObj['params'] = data
                    } else {
                        requestObj['data'] = data
                    }

                    console.log('requestObj>>>', requestObj)

                    return request(requestObj)
                },
                // fetcher: ({
                //     url,
                //     method,
                //     data,
                //     config,
                //     headers
                // }) => {
                //     config = config || {}
                //     config.headers = config.headers || {}
                //     config.withCredentials = true

                //     if (config.cancelExecutor) {
                //         config.cancelToken = new axios.CancelToken(config.cancelExecutor)
                //     }

                //     config.headers = headers || {}

                //     const token = storage.get(ACCESS_TOKEN)
                //     if (token) {
                //         config.headers['Authorization'] = 'Bearer ' + token // 让每个请求携带自定义token 请根据实际情况自行修改
                //     }
                //     config.method = method

                //     if (method === 'get' && data) {
                //         config.params = data
                //     } else if (data && data instanceof FormData) {
                //         // config.headers = config.headers || {};
                //         // config.headers['Content-Type'] = 'multipart/form-data';
                //     } else if (data &&
                //         typeof data !== 'string' &&
                //         !(data instanceof Blob) &&
                //         !(data instanceof ArrayBuffer)
                //     ) {
                //         data = JSON.stringify(data)
                //         // config.headers = config.headers || {};
                //         config.headers['Content-Type'] = 'application/json'
                //     }

                //     data && (config.data = data)
                //     console.log('url>>>>>', url)
                //     console.log('config>>>>>', JSON.parse(JSON.stringify(config)))
                //     // const request = axios.create({
                //     //     // API 请求的默认前缀
                //     //     baseURL: process.env.VUE_APP_BASE_API,
                //     //     timeout: 100000 // 请求超时时间
                //     // })
                //     // axios.defaults.baseURL = process.env.VUE_APP_BASE_API
                //     // url = process.env.VUE_APP_BASE_API + url
                //     return axios(url, config)
                // },
                isCancel: (e) => axios.isCancel(e),
                alert,
                notify: (type, msg) => {
                    // toast[type] ? toast[type](msg, type === 'error' ? '系统错误' : '系统消息') : console.warn('[Notify]', type, msg)
                    console.log('[notify]', type, msg)
                    if (msg !== 'fetchFailed') {
                        this.$notification[type]({
                            message: msg
                        })
                    }
                },
                confirm,
                copy: (contents, options = {}) => {
                    const ret = copy(contents, options)
                    ret && (!options || options.shutup !== true) && this.$message.success('内容已拷贝到剪切板', 3)

                    return ret
                },
                jumpTo(to, action) {
                    console.log('跳转>>>>jumpTo>>>>>to>>>>>', to)
                    console.log('跳转>>>>jumpTo>>>>>action>>>>>', action)
                    setTimeout(() => {
                        if (action.actionType === 'link') {
                            _vm.$router.push({ path: to }).catch(() => { })
                        } else {
                            window.open(action.url)
                        }
                    }, 300);

                    // debugger
                }
            }
        },

        updateRoute (location, replace) {
            if (location === 'goBack') {
                return this.$router.go(-1)
            }
            this.$router[replace ? 'replace' : 'push'](this.normalizeLink(location))
        },

        handleAction (e, action) {
            // this.env.alert(`没有识别的动作：${JSON.stringify(action)}`)
        },

        normalizeLink (to) {
            if (/^\/api\//.test(to)) {
                return to
            }
            to = to || ''
            const location = window.location
            if (to && to[0] === '#') {
                to = location.pathname + location.search + to
            } else if (to && to[0] === '?') {
                to = location.pathname + to
            }
            const idx = to.indexOf('?')
            const idx2 = to.indexOf('#')
            let pathname = ~idx ? to.substring(0, idx) : ~idx2 ? to.substring(0, idx2) : to
            const search = ~idx ? to.substring(idx, ~idx2 ? idx2 : undefined) : ''
            const hash = ~idx2 ? to.substring(idx2) : ''
            if (!pathname) {
                pathname = location.pathname
            // eslint-disable-next-line eqeqeq
            } else if (pathname[0] != '/' && !/^https?:\/\//.test(pathname)) {
                const relativeBase = location.pathname
                const paths = relativeBase.split('/')
                paths.pop()
                let m
                while ((m = /^\.\.?\//.exec(pathname))) {
                    if (m[0] === '../') {
                        paths.pop()
                    }
                    pathname = pathname.substring(m[0].length)
                }
                pathname = paths.concat(pathname).join('/')
            }
            return pathname + search + hash
        }
    }
}
</script>
<style lang='less' scoped>

</style>
