<template>
  <div class="menue-left-top">
    <!-- <div class="menue-left-top-logo">顺联伙伴</div> -->
    <img
      v-if="defaultSettings.showMenueTopType==1"
      class="menue-left-top-logo"
      src="@/assets/menuTopLogo.png"
      alt=""
      srcset=""
    >
    <div
      v-else-if="defaultSettings.showMenueTopType==2"
      class="menue-left-top-logo"
      style="width: 100%;"
      @click="toRoute"
    >
      <img
        class="logoBoxLogo"
        src="@/assets/logo.png"
      >
      <span class="logoBoxFont">{{ defaultSettings.menuTopTitle }}</span>
    </div>
    <div
      v-else-if="defaultSettings.showMenueTopType==3"
      class="menue-left-top-logo"
    >{{ defaultSettings.menuTopTitle }}</div>
    <div
      v-else-if="defaultSettings.showMenueTopType==4"
      class="menue-left-top-logo"
      style="width: 100%;display: flex;justify-content: center;align-items: center;"
    >
      <img
        class="logoBoxLogo"
        src="@/assets/logo.png"
      >
      <!-- <span class="logoBoxFont">{{ defaultSettings.menuTopTitle }}</span> -->
    </div>
    <div class="menue-left-top-search">
      <a-input
        class="font12R"
        v-model="searchMenu"
        placeholder="请输入"
        allow-clear
        @change="onChange"
      >
        <a-icon
          slot="prefix"
          type="search"
          style="font-size: 12px;color: #527ACC;"
        />

      </a-input>
    </div>
  </div>
</template>

<script>
import defaultSettings from '@/config/defaultSettings'
export default {
  components: {
  },
  data () {
    return {
      defaultSettings,
      searchMenu: ''
    }
  },
  methods: {
    onChange () {
      this.$emit('searchChange', this.searchMenu)
    },
    toRoute () {
      this.$router.push({ path: '/DataScreen/DataScreen' })
    }
  }

}
</script>

<style lang="less" scoped>
.menue-left-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  // padding: 0 16px;
  padding-bottom: 22px;

  .menue-left-top-logo {
    // width: 155px;
    // width: 100%;
    width: calc(100% - 32px);
    height: 42px;
    margin-bottom: 20px;
    margin-right: 17px;
    // font-size: 20px;
    font-size: 30px;
    color: white;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    .logoBoxLogo {
      height: 100% !important;
      margin-right: 16px;
    }

    .logoBoxFont {
      font-size: 23px;
      color: #ffffff;
      font-weight: bold;
      font-family: '思源-Bold';
    }
  }

  .menue-left-top-search {
    display: flex;
    align-items: center;
    width: calc(100% - 32px);
    height: 30px;
    background: #ffffff;
    box-shadow: inset 0px 3px 6px 1px rgba(40, 120, 255, 0.06);
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    overflow: hidden;
    margin: 0 auto;

    /deep/.ant-input {
      padding-top: 0;
      padding-bottom: 0;
      font-size: 12px;
      font-family: '思源-Regular';
      color: #2878ff;
    }
  }
}
</style>
