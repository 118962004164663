/* eslint-disable no-template-curly-in-string */
/**
 * @file 编辑器扩展, 增加自定义组件
 */
import { BasePlugin, registerEditorPlugin } from 'amis-editor'

export class AmapPlugin extends BasePlugin {
  // 关联渲染器名字
  rendererName = 'amappolygon';
  $schema = '/schemas/UnkownSchema.json';
  notRenderFormZone = true;
  // 组件名称（组件面板显示的Title）
  name = '高德-电子围栏';
  order=0; // 排序
  description = '高德-电子围栏展示信息';
  tags = ['布局容器', 'A自定义'];
  icon = 'fa fa-file-code-o';
  scaffold = { // 插入到页面时需要
    type: 'amappolygon',
    isValidateSave: true,
    'amap_key': 'f81089a8c2236d538f9abe9570ae57a4',
    'amap_security': 'bb44a80bc8964703b295d5a8f3f4f3ed',
    'amap_width': '100%',
    'amap_height': '500px',
    'amap_list': 'path',
    'amap_create_time': new Date().getTime()
  };
  previewSchema = { // 组件面板预览时需要
    type: 'amappolygon',
    label: 'amappolygon'
  };

  // 容器类组件必需字段
  // regions = [
  //   {
  //     key: 'body',
  //     label: '内容区'
  //   }
  // ];

  panelTitle = '配置'; // 右侧属性面板Title
  panelControls = [ // 右侧属性面板配置项
    {
      type: 'tabs',
      tabsMode: 'line',
      className: 'editor-prop-config-tabs',
      contentClassName: 'no-border editor-prop-config-tabs-cont hoverShowScrollBar',
      linksClassName: 'editor-prop-config-tabs-links aa',
      tabs: [
        {
          title: '常规',
          className: 'p-none',
          controls: [
            {
              'type': 'collapse-group',
              className: 'ae-formItemControl ae-styleControl',
              'activeKey': [
                'amap_11', 'amap_12'
              ],
              body: [
                {
                  'type': 'collapse',
                  'key': 'amap_11',
                  'header': '基本',
                  headingClassName: 'ae-formItemControl-header ae-Collapse-header',
                  bodyClassName: 'ae-formItemControl-body',
                  'body': [
                    {
                      type: 'input-text',
                      name: 'amap_key',
                      label: '地图key',
                      value: '1313e880c1d91e54afab3149bc2eec4c',
                      // 'mode': 'inline',
                      'required': true

                    },
                    {
                      type: 'input-text',
                      name: 'amap_security',
                      label: '地图密钥',
                      value: 'f7809e471d357b7be282adb82d2f0e4b',
                      // 'mode': 'inline',
                      'required': true
                    }
                  ]
                }
              ]
            }

          ]
        },
        {
          title: '外观',
          className: 'p-none',
          controls: [
            {
              'type': 'collapse-group',
              className: 'ae-formItemControl ae-styleControl',
              'activeKey': [
                'amap_21', 'amap_22', 'amap_23'
              ],
              'body': [
                {
                  'type': 'collapse',
                  'key': 'amap_21',
                  'header': '基本',
                  headingClassName: 'ae-formItemControl-header ae-Collapse-header',
                  bodyClassName: 'ae-formItemControl-body',
                  'body': [
                    {
                      type: 'input-text',
                      name: 'amap_width',
                      label: '宽度',
                      value: '90%'
                    },
                    {
                      type: 'input-text',
                      name: 'amap_height',
                      label: '高度',
                      value: '500px'
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          title: '数据',
          className: 'p-none',
          controls: [
            {
              'type': 'collapse-group',
              className: 'ae-formItemControl ae-styleControl',
              'activeKey': [
                'amap_31', 'amap_32'
              ],
              'body': [
                {
                  'type': 'collapse',
                  'key': 'amap_31',
                  'header': '绑定字段',
                  headingClassName: 'ae-formItemControl-header ae-Collapse-header',
                  bodyClassName: 'ae-formItemControl-body',
                  'body': [
                    {
                      type: 'input-text',
                      name: 'amap_list',
                      label: '数据',
                      value: 'path'                    }

                  ]
                }
              ]
            }
          ]
        }
      ]

    }

  ];
}

registerEditorPlugin(AmapPlugin)
