/* eslint-disable no-template-curly-in-string */
/**
 * @file 编辑器扩展, 增加自定义组件
 */
import { BasePlugin, registerEditorPlugin } from 'amis-editor'

export class InfoGridPlugin extends BasePlugin {
  // 关联渲染器名字
  rendererName = 'cham-iframe-vue';
  $schema = '/schemas/UnkownSchema.json';
  notRenderFormZone = true;
  // 组件名称（组件面板显示的Title）
  name = 'chamiframevue';
  order = 0; // 排序
  description = '自定义iframe嵌入vue页面,需要自己适配页面样式';
  tags = ['A自定义'];
  icon = 'fa fa-file-code-o';
  scaffold = {
    "type": "iframe",
    "src": "/controlPage?id=${id}",
    "height": "100%"
  };
  previewSchema = {
    "type": "iframe",
    "src": "/controlPage?id=${id}",
    "height": "100%"
  };

  // 容器类组件必需字段
  // regions = [
  //   {
  //     key: 'body',
  //     label: '内容区'
  //   }
  // ];

  panelTitle = '配置'; // 右侧属性面板Title

  panelControls = [ // 右侧属性面板配置项

  ];
}

registerEditorPlugin(InfoGridPlugin)
