/* eslint-disable no-template-curly-in-string */
/**
 * @file 编辑器扩展, 增加自定义组件
 */
import { BasePlugin, registerEditorPlugin } from 'amis-editor'

export class InfoGridPlugin extends BasePlugin {
  // 关联渲染器名字
  rendererName = 'com-pie-chart';
  $schema = '/schemas/UnkownSchema.json';
  notRenderFormZone = true;
  // 组件名称（组件面板显示的Title）
  name = '报警列表';
  order=0; // 排序
  description = '报警列表展示信息';
  tags = ['布局容器', '驾驶舱', 'A自定义'];
  icon = 'fa fa-file-code-o';
  scaffold = { // 插入到页面时需要
    'type': 'card',
    'header': {
      'title': '',
      'subTitle': ''
    },
    'body': [
      {
        'type': 'flex',
        'className': 'p-1',
        'items': [
          {
            'type': 'container',
            'body': [
              {
                'type': 'container',
                'id': 'u:f79ae4b4dbea',
                'body': [
                  {
                    'type': 'tpl',
                    'tpl': '待处理项报警异常',
                    'inline': true,
                    'wrapperComponent': '',
                    'id': 'u:2a4ab6b0a461',
                    'style': {
                      'boxShadow': ' 0px 0px 0px 0px transparent'
                    }
                  },
                  {
                    'type': 'icon',
                    'icon': 'fas fa-sync-alt',
                    'vendor': '',
                    'id': 'u:327ff415695c',
                    'themeCss': {
                      'className': {
                        'padding-and-margin:default': {
                          'marginTop': '0',
                          'marginRight': '0',
                          'marginBottom': '0',
                          'marginLeft': '10px'
                        },
                        'font': {
                          'color': '#0052c7'
                        }
                      }
                    },
                    'className': 'className-327ff415695c'
                  }
                ],
                'style': {
                  'position': 'static',
                  'display': 'block',
                  'boxShadow': ' 0px 0px 0px 0px transparent'
                },
                'wrapperBody': false,
                'isFixedHeight': false,
                'isFixedWidth': false
              },
              {
                'type': 'flex',
                'className': 'p-1',
                'items': [
                  {
                    'type': 'container',
                    'body': [
                      {
                        'type': 'progress',
                        'mode': 'circle',
                        'value': '${100}',
                        'strokeWidth': 10,
                        'valueTpl': "<span style='font-size:20px;color:#FF2C2C;'>${alarmList.length}</span><span>项</span><br/><span>待处理</span>",
                        'id': 'u:36a019e2db87',
                        'placeholder': '-',
                        'progressClassName': 'w-lg',
                        'map': [
                          {
                            'color': '#ff2c2c',
                            'value': 100
                          }
                        ],
                        'gapDegree': 0,
                        'gapPosition': '',
                        'showLabel': true,
                        'menuTpl': '',
                        'className': ''
                      }
                    ],
                    'size': 'xs',
                    'style': {
                      'position': 'static',
                      'display': 'flex',
                      'flex': '1 1 auto',
                      'flexGrow': 1,
                      'flexBasis': 'auto',
                      'boxShadow': ' 0px 0px 0px 0px transparent',
                      'marginTop': '10px',
                      'marginRight': '0',
                      'marginBottom': '10px',
                      'marginLeft': '0',
                      'flexWrap': 'nowrap',
                      'justifyContent': 'center',
                      'alignItems': 'center'
                    },
                    'wrapperBody': false,
                    'isFixedHeight': false,
                    'isFixedWidth': false,
                    'id': 'u:d88a029ed605'
                  },
                  {
                    'type': 'container',
                    'body': [
                      {
                        'type': 'button',
                        'label': '去处理',
                        'onEvent': {
                          'click': {
                            'actions': [
                            ]
                          }
                        },
                        'id': 'u:a1b0634f31d1',
                        'themeCss': {
                          'className': {
                            'radius:default': {
                              'top-left-border-radius': '15px',
                              'top-right-border-radius': '15px',
                              'bottom-right-border-radius': '15px',
                              'bottom-left-border-radius': '15px'
                            },
                            'background:default': 'rgba(255, 44, 44, 0.06)',
                            'border:default': {
                              'top-border-style': 'solid',
                              'left-border-style': 'solid',
                              'right-border-style': 'solid',
                              'bottom-border-style': 'solid',
                              'border': '1px solid #FF2C2C'
                            },
                            'font:default': {
                              'color': '#ff2c2c'
                            }
                          }
                        },
                        'className': 'className-a1b0634f31d1',
                        'level': 'default'
                      }
                    ],
                    'size': 'xs',
                    'style': {
                      'position': 'static',
                      'display': 'flex',
                      'flex': '1 1 auto',
                      'flexGrow': 1,
                      'flexBasis': 'auto',
                      'boxShadow': ' 0px 0px 0px 0px transparent',
                      'flexWrap': 'nowrap',
                      'justifyContent': 'center',
                      'alignItems': 'center'
                    },
                    'wrapperBody': false,
                    'isFixedHeight': false,
                    'isFixedWidth': false,
                    'id': 'u:d83cdc454a11'
                  }
                ],
                'style': {
                  'position': 'relative',
                  'inset': 'auto',
                  'flexWrap': 'nowrap',
                  'boxShadow': ' 0px 0px 0px 0px transparent',
                  'flexDirection': 'column',
                  'justifyContent': 'space-between',
                  'alignItems': 'center',
                  'height': '100%',
                  'overflowY': 'visible'
                },
                'id': 'u:f172b1727a63',
                'isFixedHeight': true,
                'isFixedWidth': false
              }
            ],
            'size': 'xs',
            'style': {
              'position': 'static',
              'display': 'block',
              'flex': '0 0 150px',
              'flexBasis': '369px',
              'boxShadow': ' 0px 0px 0px 0px transparent',
              'flexDirection': 'column',
              'overflowX': 'visible',
              'right-border-style': 'dashed'
            },
            'wrapperBody': false,
            'isFixedHeight': false,
            'isFixedWidth': false,
            'id': 'u:4d0eae94fcc9',
            'className': 'b-r'
          },
          {
            'type': 'container',
            'body': [
              {
                'type': 'container',
                'body': [
                  {
                    'type': 'grid',
                    'columns': [
                      {
                        'body': [
                          {
                            'type': 'flex',
                            'className': 'p-1',
                            'items': [
                              {
                                'type': 'container',
                                'body': [
                                  {
                                    'type': 'tpl',
                                    'tpl': '去处理',
                                    'inline': true,
                                    'wrapperComponent': '',
                                    'id': 'u:ca0276fe105d',
                                    'style': {
                                      'boxShadow': ' 0px 0px 0px 0px transparent',
                                      'color': '#34c700'
                                    }
                                  },
                                  {
                                    'type': 'tpl',
                                    'tpl': '异常报警：0',
                                    'inline': true,
                                    'wrapperComponent': '',
                                    'id': 'u:f53b14b01f80',
                                    'style': {
                                      'boxShadow': ' 0px 0px 0px 0px transparent',
                                      'color': '#1c1f27',
                                      'marginTop': '0',
                                      'marginRight': '0',
                                      'marginBottom': '0',
                                      'marginLeft': '0'
                                    }
                                  }
                                ],
                                'size': 'xs',
                                'style': {
                                  'position': 'static',
                                  'display': 'flex',
                                  'flex': '1 1 auto',
                                  'flexGrow': 1,
                                  'flexBasis': 'auto',
                                  'boxShadow': ' 0px 0px 0px 0px transparent',
                                  'flexWrap': 'nowrap',
                                  'flexDirection': 'column',
                                  'alignItems': 'flex-start',
                                  'justifyContent': 'center'
                                },
                                'wrapperBody': false,
                                'isFixedHeight': false,
                                'isFixedWidth': false,
                                'id': 'u:c6435c11fa71'
                              },
                              {
                                'type': 'container',
                                'body': [
                                  {
                                    'type': 'icon',
                                    'icon': 'fas fa-chevron-circle-right',
                                    'vendor': '',
                                    'id': 'u:a2be67d6318a',
                                    'themeCss': {
                                      'className': {
                                        'font': {
                                          'color': '#34c700',
                                          'fontSize': '25px'
                                        }
                                      }
                                    },
                                    'className': 'className-a2be67d6318a'
                                  }
                                ],
                                'size': 'xs',
                                'style': {
                                  'position': 'static',
                                  'display': 'flex',
                                  'flex': '0 0 150px',
                                  'flexBasis': '30px',
                                  'boxShadow': ' 0px 0px 0px 0px transparent',
                                  'flexWrap': 'nowrap',
                                  'justifyContent': 'center',
                                  'alignItems': 'center',
                                  'overflowY': 'visible',
                                  'overflowX': 'visible'
                                },
                                'wrapperBody': false,
                                'isFixedHeight': false,
                                'isFixedWidth': false,
                                'id': 'u:729a1580a2a1'
                              }
                            ],
                            'style': {
                              'position': 'relative',
                              'inset': 'auto',
                              'flexWrap': 'nowrap',
                              'boxShadow': ' 0px 0px 0px 0px transparent'
                            },
                            'id': 'u:d0d07a5b7a95',
                            'isFixedHeight': false,
                            'isFixedWidth': false
                          }
                        ],
                        'id': 'u:7ecfe8bea603',
                        'style': {
                          'boxShadow': ' 0px 0px 0px 0px transparent',
                          'background': 'rgba(52, 199, 0, 0.06)',
                          'radius': {
                            'top-left-border-radius': '6px',
                            'top-right-border-radius': '6px',
                            'bottom-left-border-radius': '6px',
                            'bottom-right-border-radius': '6px'
                          }
                        }
                      },
                      {
                        'body': [
                          {
                            'type': 'flex',
                            'className': 'p-1',
                            'items': [
                              {
                                'type': 'container',
                                'body': [
                                  {
                                    'type': 'tpl',
                                    'tpl': '去处理',
                                    'inline': true,
                                    'wrapperComponent': '',
                                    'id': 'u:e5c4603d9da6',
                                    'style': {
                                      'boxShadow': ' 0px 0px 0px 0px transparent',
                                      'color': '#ff9e30'
                                    }
                                  },
                                  {
                                    'type': 'tpl',
                                    'tpl': '故障报警：0',
                                    'inline': true,
                                    'wrapperComponent': '',
                                    'id': 'u:36dc96318d30',
                                    'style': {
                                      'boxShadow': ' 0px 0px 0px 0px transparent',
                                      'color': '#1c1f27',
                                      'marginTop': '0',
                                      'marginRight': '0',
                                      'marginBottom': '0',
                                      'marginLeft': '0'
                                    }
                                  }
                                ],
                                'size': 'xs',
                                'style': {
                                  'position': 'static',
                                  'display': 'flex',
                                  'flex': '1 1 auto',
                                  'flexGrow': 1,
                                  'flexBasis': 'auto',
                                  'boxShadow': ' 0px 0px 0px 0px transparent',
                                  'flexWrap': 'nowrap',
                                  'flexDirection': 'column',
                                  'alignItems': 'flex-start',
                                  'justifyContent': 'center'
                                },
                                'wrapperBody': false,
                                'isFixedHeight': false,
                                'isFixedWidth': false,
                                'id': 'u:a7c909ebce69'
                              },
                              {
                                'type': 'container',
                                'body': [
                                  {
                                    'type': 'icon',
                                    'icon': 'fas fa-chevron-circle-right',
                                    'vendor': '',
                                    'id': 'u:9b266c1681a3',
                                    'themeCss': {
                                      'className': {
                                        'font': {
                                          'color': '#ff9e30',
                                          'fontSize': '25px'
                                        }
                                      }
                                    },
                                    'className': 'className-a2be67d6318a className-9b266c1681a3'
                                  }
                                ],
                                'size': 'xs',
                                'style': {
                                  'position': 'static',
                                  'display': 'flex',
                                  'flex': '0 0 150px',
                                  'flexBasis': '30px',
                                  'boxShadow': ' 0px 0px 0px 0px transparent',
                                  'flexWrap': 'nowrap',
                                  'justifyContent': 'center',
                                  'alignItems': 'center',
                                  'overflowY': 'visible',
                                  'overflowX': 'visible'
                                },
                                'wrapperBody': false,
                                'isFixedHeight': false,
                                'isFixedWidth': false,
                                'id': 'u:59455e61abfe'
                              }
                            ],
                            'style': {
                              'position': 'relative',
                              'inset': 'auto',
                              'flexWrap': 'nowrap',
                              'boxShadow': ' 0px 0px 0px 0px transparent'
                            },
                            'id': 'u:21ca15437b09',
                            'isFixedHeight': false,
                            'isFixedWidth': false
                          }
                        ],
                        'id': 'u:26eac886b7ff',
                        'style': {
                          'boxShadow': ' 0px 0px 0px 0px transparent',
                          'background': 'rgba(255, 158, 48, 0.06)',
                          'radius': {
                            'top-left-border-radius': '6px',
                            'top-right-border-radius': '6px',
                            'bottom-left-border-radius': '6px',
                            'bottom-right-border-radius': '6px'
                          },
                          'marginTop': '0',
                          'marginRight': '84px',
                          'marginBottom': '0',
                          'marginLeft': '84px'
                        }
                      },
                      {
                        'body': [
                          {
                            'type': 'flex',
                            'className': 'p-1',
                            'items': [
                              {
                                'type': 'container',
                                'body': [
                                  {
                                    'type': 'tpl',
                                    'tpl': '去处理',
                                    'inline': true,
                                    'wrapperComponent': '',
                                    'id': 'u:21d954df1caf',
                                    'style': {
                                      'boxShadow': ' 0px 0px 0px 0px transparent',
                                      'color': '#0052c7'
                                    }
                                  },
                                  {
                                    'type': 'tpl',
                                    'tpl': '业务报警：0',
                                    'inline': true,
                                    'wrapperComponent': '',
                                    'id': 'u:77ef647f15ec',
                                    'style': {
                                      'boxShadow': ' 0px 0px 0px 0px transparent',
                                      'color': '#1c1f27',
                                      'marginTop': '0',
                                      'marginRight': '0',
                                      'marginBottom': '0',
                                      'marginLeft': '0'
                                    }
                                  }
                                ],
                                'size': 'xs',
                                'style': {
                                  'position': 'static',
                                  'display': 'flex',
                                  'flex': '1 1 auto',
                                  'flexGrow': 1,
                                  'flexBasis': 'auto',
                                  'boxShadow': ' 0px 0px 0px 0px transparent',
                                  'flexWrap': 'nowrap',
                                  'flexDirection': 'column',
                                  'alignItems': 'flex-start',
                                  'justifyContent': 'center'
                                },
                                'wrapperBody': false,
                                'isFixedHeight': false,
                                'isFixedWidth': false,
                                'id': 'u:222364a62fc6'
                              },
                              {
                                'type': 'container',
                                'body': [
                                  {
                                    'type': 'icon',
                                    'icon': 'fas fa-chevron-circle-right',
                                    'vendor': '',
                                    'id': 'u:ae971612c2b3',
                                    'themeCss': {
                                      'className': {
                                        'font': {
                                          'color': '#0052c7',
                                          'fontSize': '25px'
                                        }
                                      }
                                    },
                                    'className': 'className-a2be67d6318a className-ae971612c2b3'
                                  }
                                ],
                                'size': 'xs',
                                'style': {
                                  'position': 'static',
                                  'display': 'flex',
                                  'flex': '0 0 150px',
                                  'flexBasis': '30px',
                                  'boxShadow': ' 0px 0px 0px 0px transparent',
                                  'flexWrap': 'nowrap',
                                  'justifyContent': 'center',
                                  'alignItems': 'center',
                                  'overflowY': 'visible',
                                  'overflowX': 'visible'
                                },
                                'wrapperBody': false,
                                'isFixedHeight': false,
                                'isFixedWidth': false,
                                'id': 'u:ea7c5a25f892'
                              }
                            ],
                            'style': {
                              'position': 'relative',
                              'inset': 'auto',
                              'flexWrap': 'nowrap',
                              'boxShadow': ' 0px 0px 0px 0px transparent'
                            },
                            'id': 'u:f6b951449275',
                            'isFixedHeight': false,
                            'isFixedWidth': false
                          }
                        ],
                        'id': 'u:b094709a0629',
                        'style': {
                          'boxShadow': ' 0px 0px 0px 0px transparent',
                          'background': 'rgba(0, 82, 199, 0.06)',
                          'radius': {
                            'top-left-border-radius': '6px',
                            'top-right-border-radius': '6px',
                            'bottom-left-border-radius': '6px',
                            'bottom-right-border-radius': '6px'
                          }
                        }
                      }
                    ],
                    'id': 'u:89718efa59d7',
                    'style': {
                      'boxShadow': ' 0px 0px 0px 0px transparent',
                      'paddingTop': '0',
                      'paddingRight': '0',
                      'paddingBottom': '0',
                      'paddingLeft': '0',
                      'marginTop': '0',
                      'marginRight': '0',
                      'marginBottom': '0',
                      'marginLeft': '0'
                    }
                  }
                ],
                'style': {
                  'position': 'static',
                  'display': 'flex',
                  'flex': '0 0 auto',
                  'boxShadow': ' 0px 0px 0px 0px transparent',
                  'flexWrap': 'nowrap',
                  'justifyContent': 'space-between'
                },
                'wrapperBody': false,
                'id': 'u:c433573f4bfc',
                'isFixedWidth': false
              },
              {
                'type': 'container',
                'body': [
                  {
                    'type': 'list',
                    'listItem': {
                      'body': [
                        {
                          'type': 'container',
                          'id': 'u:9fdf9aeb4f83',
                          'body': [
                            {
                              'type': 'flex',
                              'className': 'p-1',
                              'items': [
                                {
                                  'type': 'container',
                                  'body': [
                                    {
                                      'type': 'mapping',
                                      'value': 1,
                                      'map': {
                                        '1': '异常报警',
                                        '2': '故障报警',
                                        '3': '业务报警'
                                      },
                                      'itemSchema': {
                                        'type': 'tag',
                                        'label': '${item}',
                                        'id': 'u:f7f1287c5655'
                                      },
                                      'id': 'u:ea46385d60d4'
                                    }
                                  ],
                                  'size': 'xs',
                                  'style': {
                                    'position': 'static',
                                    'display': 'block',
                                    'flex': '1 1 auto',
                                    'flexGrow': 1,
                                    'flexBasis': 'auto',
                                    'boxShadow': ' 0px 0px 0px 0px transparent'
                                  },
                                  'wrapperBody': false,
                                  'isFixedHeight': false,
                                  'isFixedWidth': false,
                                  'id': 'u:c6435c11fa71'
                                },
                                {
                                  'type': 'container',
                                  'body': [
                                    {
                                      'type': 'tpl',
                                      'tpl': '${deviceName}      ${alarmContent}',
                                      'inline': true,
                                      'wrapperComponent': '',
                                      'id': 'u:7bb49313f279',
                                      'style': {
                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                      }
                                    }
                                  ],
                                  'size': 'xs',
                                  'style': {
                                    'position': 'static',
                                    'display': 'block',
                                    'flex': '1 1 auto',
                                    'flexGrow': 1,
                                    'flexBasis': 'auto',
                                    'boxShadow': ' 0px 0px 0px 0px transparent'
                                  },
                                  'wrapperBody': false,
                                  'isFixedHeight': false,
                                  'isFixedWidth': false,
                                  'id': 'u:88a002a9674e'
                                },
                                {
                                  'type': 'container',
                                  'body': [
                                    {
                                      'type': 'tpl',
                                      'tpl': '报警值：${value}',
                                      'inline': true,
                                      'wrapperComponent': '',
                                      'id': 'u:8a6423c3e3fd',
                                      'style': {
                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                      }
                                    }
                                  ],
                                  'size': 'xs',
                                  'style': {
                                    'position': 'static',
                                    'display': 'block',
                                    'flex': '0 0 150px',
                                    'flexBasis': '300px',
                                    'boxShadow': ' 0px 0px 0px 0px transparent',
                                    'overflowX': 'visible'
                                  },
                                  'wrapperBody': false,
                                  'isFixedHeight': false,
                                  'isFixedWidth': false,
                                  'id': 'u:a00add8a473c'
                                }
                              ],
                              'style': {
                                'position': 'relative',
                                'inset': 'auto',
                                'flexWrap': 'nowrap',
                                'flex': '1 1 auto',
                                'boxShadow': ' 0px 0px 0px 0px transparent',
                                'flexBasis': '150px',
                                'overflowX': 'auto',
                                'flexGrow': 1
                              },
                              'id': 'u:70ac84d61ff3',
                              'label': 'Flex 布局',
                              'isFixedHeight': false
                            }
                          ],
                          'style': {
                            'position': 'static',
                            'display': 'flex',
                            'boxShadow': ' 0px 0px 0px 0px transparent',
                            'flexWrap': 'nowrap',
                            'width': '100%',
                            'overflowX': 'visible',
                            'margin': '0'
                          },
                          'wrapperBody': false,
                          'label': '',
                          'isFixedHeight': false,
                          'isFixedWidth': true
                        }
                      ],
                      'actions': [
                        {
                          'icon': '',
                          'type': 'button',
                          'id': 'u:f94b947d7575',
                          'label': '去处理'
                        }
                      ],
                      'id': 'u:f1394e000770'
                    },
                    'id': 'u:5fa98fcab469',
                    'source': '${alarmList}',
                    'className': 'no-border overflow-auto h-80',
                    'itemClassName': 'no-border'
                  }
                ],
                'size': 'xs',
                'style': {
                  'position': 'static',
                  'display': 'block',
                  'flex': '1 1 auto',
                  'flexGrow': 1,
                  'flexBasis': 'auto',
                  'boxShadow': ' 0px 0px 0px 0px transparent',
                  'marginTop': '10px',
                  'marginRight': '0',
                  'marginBottom': '0',
                  'marginLeft': '0'
                },
                'wrapperBody': false,
                'isFixedHeight': false,
                'isFixedWidth': false,
                'id': 'u:b2867f1a05ec'
              }
            ],
            'size': 'xs',
            'style': {
              'position': 'static',
              'display': 'flex',
              'flex': '1 1 auto',
              'flexGrow': 6,
              'flexBasis': 'auto',
              'boxShadow': ' 0px 0px 0px 0px transparent',
              'flexWrap': 'nowrap',
              'flexDirection': 'column',
              'paddingTop': '0',
              'paddingRight': '0',
              'paddingBottom': '0',
              'paddingLeft': '40px'
            },
            'wrapperBody': false,
            'isFixedHeight': false,
            'isFixedWidth': false,
            'id': 'u:537f65561217'
          }
        ],
        'style': {
          'position': 'relative',
          'inset': 'auto',
          'flexWrap': 'nowrap',
          'boxShadow': ' 0px 0px 0px 0px transparent'
        },
        'id': 'u:9854ac755d4a',
        'isFixedHeight': false,
        'isFixedWidth': false
      }
    ],
    'actions': [
      {
        'type': 'button',
        'label': '',
        'actionType': 'dialog',
        'dialog': {
          'title': '标题',
          'body': '内容'
        },
        'id': 'u:78e2e7098e35'
      }
    ]
  };
  previewSchema = { // 组件面板预览时需要
    'type': 'card',
    'header': {
      'title': '',
      'subTitle': ''
    },
    'body': [
      {
        'type': 'flex',
        'className': 'p-1',
        'items': [
          {
            'type': 'container',
            'body': [
              {
                'type': 'container',
                'id': 'u:f79ae4b4dbea',
                'body': [
                  {
                    'type': 'tpl',
                    'tpl': '待处理项报警异常',
                    'inline': true,
                    'wrapperComponent': '',
                    'id': 'u:2a4ab6b0a461',
                    'style': {
                      'boxShadow': ' 0px 0px 0px 0px transparent'
                    }
                  },
                  {
                    'type': 'icon',
                    'icon': 'fas fa-sync-alt',
                    'vendor': '',
                    'id': 'u:327ff415695c',
                    'themeCss': {
                      'className': {
                        'padding-and-margin:default': {
                          'marginTop': '0',
                          'marginRight': '0',
                          'marginBottom': '0',
                          'marginLeft': '10px'
                        },
                        'font': {
                          'color': '#0052c7'
                        }
                      }
                    },
                    'className': 'className-327ff415695c'
                  }
                ],
                'style': {
                  'position': 'static',
                  'display': 'block',
                  'boxShadow': ' 0px 0px 0px 0px transparent'
                },
                'wrapperBody': false,
                'isFixedHeight': false,
                'isFixedWidth': false
              },
              {
                'type': 'flex',
                'className': 'p-1',
                'items': [
                  {
                    'type': 'container',
                    'body': [
                      {
                        'type': 'progress',
                        'mode': 'circle',
                        'value': '${100}',
                        'strokeWidth': 10,
                        'valueTpl': "<span style='font-size:20px;color:#FF2C2C;'>${alarmList.length}</span><span>项</span><br/><span>待处理</span>",
                        'id': 'u:36a019e2db87',
                        'placeholder': '-',
                        'progressClassName': 'w-lg',
                        'map': [
                          {
                            'color': '#ff2c2c',
                            'value': 100
                          }
                        ],
                        'gapDegree': 0,
                        'gapPosition': '',
                        'showLabel': true,
                        'menuTpl': '',
                        'className': ''
                      }
                    ],
                    'size': 'xs',
                    'style': {
                      'position': 'static',
                      'display': 'flex',
                      'flex': '1 1 auto',
                      'flexGrow': 1,
                      'flexBasis': 'auto',
                      'boxShadow': ' 0px 0px 0px 0px transparent',
                      'marginTop': '10px',
                      'marginRight': '0',
                      'marginBottom': '10px',
                      'marginLeft': '0',
                      'flexWrap': 'nowrap',
                      'justifyContent': 'center',
                      'alignItems': 'center'
                    },
                    'wrapperBody': false,
                    'isFixedHeight': false,
                    'isFixedWidth': false,
                    'id': 'u:d88a029ed605'
                  },
                  {
                    'type': 'container',
                    'body': [
                      {
                        'type': 'button',
                        'label': '去处理',
                        'onEvent': {
                          'click': {
                            'actions': [
                            ]
                          }
                        },
                        'id': 'u:a1b0634f31d1',
                        'themeCss': {
                          'className': {
                            'radius:default': {
                              'top-left-border-radius': '15px',
                              'top-right-border-radius': '15px',
                              'bottom-right-border-radius': '15px',
                              'bottom-left-border-radius': '15px'
                            },
                            'background:default': 'rgba(255, 44, 44, 0.06)',
                            'border:default': {
                              'top-border-style': 'solid',
                              'left-border-style': 'solid',
                              'right-border-style': 'solid',
                              'bottom-border-style': 'solid',
                              'border': '1px solid #FF2C2C'
                            },
                            'font:default': {
                              'color': '#ff2c2c'
                            }
                          }
                        },
                        'className': 'className-a1b0634f31d1',
                        'level': 'default'
                      }
                    ],
                    'size': 'xs',
                    'style': {
                      'position': 'static',
                      'display': 'flex',
                      'flex': '1 1 auto',
                      'flexGrow': 1,
                      'flexBasis': 'auto',
                      'boxShadow': ' 0px 0px 0px 0px transparent',
                      'flexWrap': 'nowrap',
                      'justifyContent': 'center',
                      'alignItems': 'center'
                    },
                    'wrapperBody': false,
                    'isFixedHeight': false,
                    'isFixedWidth': false,
                    'id': 'u:d83cdc454a11'
                  }
                ],
                'style': {
                  'position': 'relative',
                  'inset': 'auto',
                  'flexWrap': 'nowrap',
                  'boxShadow': ' 0px 0px 0px 0px transparent',
                  'flexDirection': 'column',
                  'justifyContent': 'space-between',
                  'alignItems': 'center',
                  'height': '100%',
                  'overflowY': 'visible'
                },
                'id': 'u:f172b1727a63',
                'isFixedHeight': true,
                'isFixedWidth': false
              }
            ],
            'size': 'xs',
            'style': {
              'position': 'static',
              'display': 'block',
              'flex': '0 0 150px',
              'flexBasis': '369px',
              'boxShadow': ' 0px 0px 0px 0px transparent',
              'flexDirection': 'column',
              'overflowX': 'visible',
              'right-border-style': 'dashed'
            },
            'wrapperBody': false,
            'isFixedHeight': false,
            'isFixedWidth': false,
            'id': 'u:4d0eae94fcc9',
            'className': 'b-r'
          },
          {
            'type': 'container',
            'body': [
              {
                'type': 'container',
                'body': [
                  {
                    'type': 'grid',
                    'columns': [
                      {
                        'body': [
                          {
                            'type': 'flex',
                            'className': 'p-1',
                            'items': [
                              {
                                'type': 'container',
                                'body': [
                                  {
                                    'type': 'tpl',
                                    'tpl': '去处理',
                                    'inline': true,
                                    'wrapperComponent': '',
                                    'id': 'u:ca0276fe105d',
                                    'style': {
                                      'boxShadow': ' 0px 0px 0px 0px transparent',
                                      'color': '#34c700'
                                    }
                                  },
                                  {
                                    'type': 'tpl',
                                    'tpl': '异常报警：0',
                                    'inline': true,
                                    'wrapperComponent': '',
                                    'id': 'u:f53b14b01f80',
                                    'style': {
                                      'boxShadow': ' 0px 0px 0px 0px transparent',
                                      'color': '#1c1f27',
                                      'marginTop': '0',
                                      'marginRight': '0',
                                      'marginBottom': '0',
                                      'marginLeft': '0'
                                    }
                                  }
                                ],
                                'size': 'xs',
                                'style': {
                                  'position': 'static',
                                  'display': 'flex',
                                  'flex': '1 1 auto',
                                  'flexGrow': 1,
                                  'flexBasis': 'auto',
                                  'boxShadow': ' 0px 0px 0px 0px transparent',
                                  'flexWrap': 'nowrap',
                                  'flexDirection': 'column',
                                  'alignItems': 'flex-start',
                                  'justifyContent': 'center'
                                },
                                'wrapperBody': false,
                                'isFixedHeight': false,
                                'isFixedWidth': false,
                                'id': 'u:c6435c11fa71'
                              },
                              {
                                'type': 'container',
                                'body': [
                                  {
                                    'type': 'icon',
                                    'icon': 'fas fa-chevron-circle-right',
                                    'vendor': '',
                                    'id': 'u:a2be67d6318a',
                                    'themeCss': {
                                      'className': {
                                        'font': {
                                          'color': '#34c700',
                                          'fontSize': '25px'
                                        }
                                      }
                                    },
                                    'className': 'className-a2be67d6318a'
                                  }
                                ],
                                'size': 'xs',
                                'style': {
                                  'position': 'static',
                                  'display': 'flex',
                                  'flex': '0 0 150px',
                                  'flexBasis': '30px',
                                  'boxShadow': ' 0px 0px 0px 0px transparent',
                                  'flexWrap': 'nowrap',
                                  'justifyContent': 'center',
                                  'alignItems': 'center',
                                  'overflowY': 'visible',
                                  'overflowX': 'visible'
                                },
                                'wrapperBody': false,
                                'isFixedHeight': false,
                                'isFixedWidth': false,
                                'id': 'u:729a1580a2a1'
                              }
                            ],
                            'style': {
                              'position': 'relative',
                              'inset': 'auto',
                              'flexWrap': 'nowrap',
                              'boxShadow': ' 0px 0px 0px 0px transparent'
                            },
                            'id': 'u:d0d07a5b7a95',
                            'isFixedHeight': false,
                            'isFixedWidth': false
                          }
                        ],
                        'id': 'u:7ecfe8bea603',
                        'style': {
                          'boxShadow': ' 0px 0px 0px 0px transparent',
                          'background': 'rgba(52, 199, 0, 0.06)',
                          'radius': {
                            'top-left-border-radius': '6px',
                            'top-right-border-radius': '6px',
                            'bottom-left-border-radius': '6px',
                            'bottom-right-border-radius': '6px'
                          }
                        }
                      },
                      {
                        'body': [
                          {
                            'type': 'flex',
                            'className': 'p-1',
                            'items': [
                              {
                                'type': 'container',
                                'body': [
                                  {
                                    'type': 'tpl',
                                    'tpl': '去处理',
                                    'inline': true,
                                    'wrapperComponent': '',
                                    'id': 'u:e5c4603d9da6',
                                    'style': {
                                      'boxShadow': ' 0px 0px 0px 0px transparent',
                                      'color': '#ff9e30'
                                    }
                                  },
                                  {
                                    'type': 'tpl',
                                    'tpl': '故障报警：0',
                                    'inline': true,
                                    'wrapperComponent': '',
                                    'id': 'u:36dc96318d30',
                                    'style': {
                                      'boxShadow': ' 0px 0px 0px 0px transparent',
                                      'color': '#1c1f27',
                                      'marginTop': '0',
                                      'marginRight': '0',
                                      'marginBottom': '0',
                                      'marginLeft': '0'
                                    }
                                  }
                                ],
                                'size': 'xs',
                                'style': {
                                  'position': 'static',
                                  'display': 'flex',
                                  'flex': '1 1 auto',
                                  'flexGrow': 1,
                                  'flexBasis': 'auto',
                                  'boxShadow': ' 0px 0px 0px 0px transparent',
                                  'flexWrap': 'nowrap',
                                  'flexDirection': 'column',
                                  'alignItems': 'flex-start',
                                  'justifyContent': 'center'
                                },
                                'wrapperBody': false,
                                'isFixedHeight': false,
                                'isFixedWidth': false,
                                'id': 'u:a7c909ebce69'
                              },
                              {
                                'type': 'container',
                                'body': [
                                  {
                                    'type': 'icon',
                                    'icon': 'fas fa-chevron-circle-right',
                                    'vendor': '',
                                    'id': 'u:9b266c1681a3',
                                    'themeCss': {
                                      'className': {
                                        'font': {
                                          'color': '#ff9e30',
                                          'fontSize': '25px'
                                        }
                                      }
                                    },
                                    'className': 'className-a2be67d6318a className-9b266c1681a3'
                                  }
                                ],
                                'size': 'xs',
                                'style': {
                                  'position': 'static',
                                  'display': 'flex',
                                  'flex': '0 0 150px',
                                  'flexBasis': '30px',
                                  'boxShadow': ' 0px 0px 0px 0px transparent',
                                  'flexWrap': 'nowrap',
                                  'justifyContent': 'center',
                                  'alignItems': 'center',
                                  'overflowY': 'visible',
                                  'overflowX': 'visible'
                                },
                                'wrapperBody': false,
                                'isFixedHeight': false,
                                'isFixedWidth': false,
                                'id': 'u:59455e61abfe'
                              }
                            ],
                            'style': {
                              'position': 'relative',
                              'inset': 'auto',
                              'flexWrap': 'nowrap',
                              'boxShadow': ' 0px 0px 0px 0px transparent'
                            },
                            'id': 'u:21ca15437b09',
                            'isFixedHeight': false,
                            'isFixedWidth': false
                          }
                        ],
                        'id': 'u:26eac886b7ff',
                        'style': {
                          'boxShadow': ' 0px 0px 0px 0px transparent',
                          'background': 'rgba(255, 158, 48, 0.06)',
                          'radius': {
                            'top-left-border-radius': '6px',
                            'top-right-border-radius': '6px',
                            'bottom-left-border-radius': '6px',
                            'bottom-right-border-radius': '6px'
                          },
                          'marginTop': '0',
                          'marginRight': '84px',
                          'marginBottom': '0',
                          'marginLeft': '84px'
                        }
                      },
                      {
                        'body': [
                          {
                            'type': 'flex',
                            'className': 'p-1',
                            'items': [
                              {
                                'type': 'container',
                                'body': [
                                  {
                                    'type': 'tpl',
                                    'tpl': '去处理',
                                    'inline': true,
                                    'wrapperComponent': '',
                                    'id': 'u:21d954df1caf',
                                    'style': {
                                      'boxShadow': ' 0px 0px 0px 0px transparent',
                                      'color': '#0052c7'
                                    }
                                  },
                                  {
                                    'type': 'tpl',
                                    'tpl': '业务报警：0',
                                    'inline': true,
                                    'wrapperComponent': '',
                                    'id': 'u:77ef647f15ec',
                                    'style': {
                                      'boxShadow': ' 0px 0px 0px 0px transparent',
                                      'color': '#1c1f27',
                                      'marginTop': '0',
                                      'marginRight': '0',
                                      'marginBottom': '0',
                                      'marginLeft': '0'
                                    }
                                  }
                                ],
                                'size': 'xs',
                                'style': {
                                  'position': 'static',
                                  'display': 'flex',
                                  'flex': '1 1 auto',
                                  'flexGrow': 1,
                                  'flexBasis': 'auto',
                                  'boxShadow': ' 0px 0px 0px 0px transparent',
                                  'flexWrap': 'nowrap',
                                  'flexDirection': 'column',
                                  'alignItems': 'flex-start',
                                  'justifyContent': 'center'
                                },
                                'wrapperBody': false,
                                'isFixedHeight': false,
                                'isFixedWidth': false,
                                'id': 'u:222364a62fc6'
                              },
                              {
                                'type': 'container',
                                'body': [
                                  {
                                    'type': 'icon',
                                    'icon': 'fas fa-chevron-circle-right',
                                    'vendor': '',
                                    'id': 'u:ae971612c2b3',
                                    'themeCss': {
                                      'className': {
                                        'font': {
                                          'color': '#0052c7',
                                          'fontSize': '25px'
                                        }
                                      }
                                    },
                                    'className': 'className-a2be67d6318a className-ae971612c2b3'
                                  }
                                ],
                                'size': 'xs',
                                'style': {
                                  'position': 'static',
                                  'display': 'flex',
                                  'flex': '0 0 150px',
                                  'flexBasis': '30px',
                                  'boxShadow': ' 0px 0px 0px 0px transparent',
                                  'flexWrap': 'nowrap',
                                  'justifyContent': 'center',
                                  'alignItems': 'center',
                                  'overflowY': 'visible',
                                  'overflowX': 'visible'
                                },
                                'wrapperBody': false,
                                'isFixedHeight': false,
                                'isFixedWidth': false,
                                'id': 'u:ea7c5a25f892'
                              }
                            ],
                            'style': {
                              'position': 'relative',
                              'inset': 'auto',
                              'flexWrap': 'nowrap',
                              'boxShadow': ' 0px 0px 0px 0px transparent'
                            },
                            'id': 'u:f6b951449275',
                            'isFixedHeight': false,
                            'isFixedWidth': false
                          }
                        ],
                        'id': 'u:b094709a0629',
                        'style': {
                          'boxShadow': ' 0px 0px 0px 0px transparent',
                          'background': 'rgba(0, 82, 199, 0.06)',
                          'radius': {
                            'top-left-border-radius': '6px',
                            'top-right-border-radius': '6px',
                            'bottom-left-border-radius': '6px',
                            'bottom-right-border-radius': '6px'
                          }
                        }
                      }
                    ],
                    'id': 'u:89718efa59d7',
                    'style': {
                      'boxShadow': ' 0px 0px 0px 0px transparent',
                      'paddingTop': '0',
                      'paddingRight': '0',
                      'paddingBottom': '0',
                      'paddingLeft': '0',
                      'marginTop': '0',
                      'marginRight': '0',
                      'marginBottom': '0',
                      'marginLeft': '0'
                    }
                  }
                ],
                'style': {
                  'position': 'static',
                  'display': 'flex',
                  'flex': '0 0 auto',
                  'boxShadow': ' 0px 0px 0px 0px transparent',
                  'flexWrap': 'nowrap',
                  'justifyContent': 'space-between'
                },
                'wrapperBody': false,
                'id': 'u:c433573f4bfc',
                'isFixedWidth': false
              },
              {
                'type': 'container',
                'body': [
                  {
                    'type': 'list',
                    'listItem': {
                      'body': [
                        {
                          'type': 'container',
                          'id': 'u:9fdf9aeb4f83',
                          'body': [
                            {
                              'type': 'flex',
                              'className': 'p-1',
                              'items': [
                                {
                                  'type': 'container',
                                  'body': [
                                    {
                                      'type': 'mapping',
                                      'value': 1,
                                      'map': {
                                        '1': '异常报警',
                                        '2': '故障报警',
                                        '3': '业务报警'
                                      },
                                      'itemSchema': {
                                        'type': 'tag',
                                        'label': '${item}',
                                        'id': 'u:f7f1287c5655'
                                      },
                                      'id': 'u:ea46385d60d4'
                                    }
                                  ],
                                  'size': 'xs',
                                  'style': {
                                    'position': 'static',
                                    'display': 'block',
                                    'flex': '1 1 auto',
                                    'flexGrow': 1,
                                    'flexBasis': 'auto',
                                    'boxShadow': ' 0px 0px 0px 0px transparent'
                                  },
                                  'wrapperBody': false,
                                  'isFixedHeight': false,
                                  'isFixedWidth': false,
                                  'id': 'u:c6435c11fa71'
                                },
                                {
                                  'type': 'container',
                                  'body': [
                                    {
                                      'type': 'tpl',
                                      'tpl': '${deviceName}      ${alarmContent}',
                                      'inline': true,
                                      'wrapperComponent': '',
                                      'id': 'u:7bb49313f279',
                                      'style': {
                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                      }
                                    }
                                  ],
                                  'size': 'xs',
                                  'style': {
                                    'position': 'static',
                                    'display': 'block',
                                    'flex': '1 1 auto',
                                    'flexGrow': 1,
                                    'flexBasis': 'auto',
                                    'boxShadow': ' 0px 0px 0px 0px transparent'
                                  },
                                  'wrapperBody': false,
                                  'isFixedHeight': false,
                                  'isFixedWidth': false,
                                  'id': 'u:88a002a9674e'
                                },
                                {
                                  'type': 'container',
                                  'body': [
                                    {
                                      'type': 'tpl',
                                      'tpl': '报警值：${value}',
                                      'inline': true,
                                      'wrapperComponent': '',
                                      'id': 'u:8a6423c3e3fd',
                                      'style': {
                                        'boxShadow': ' 0px 0px 0px 0px transparent'
                                      }
                                    }
                                  ],
                                  'size': 'xs',
                                  'style': {
                                    'position': 'static',
                                    'display': 'block',
                                    'flex': '0 0 150px',
                                    'flexBasis': '300px',
                                    'boxShadow': ' 0px 0px 0px 0px transparent',
                                    'overflowX': 'visible'
                                  },
                                  'wrapperBody': false,
                                  'isFixedHeight': false,
                                  'isFixedWidth': false,
                                  'id': 'u:a00add8a473c'
                                }
                              ],
                              'style': {
                                'position': 'relative',
                                'inset': 'auto',
                                'flexWrap': 'nowrap',
                                'flex': '1 1 auto',
                                'boxShadow': ' 0px 0px 0px 0px transparent',
                                'flexBasis': '150px',
                                'overflowX': 'auto',
                                'flexGrow': 1
                              },
                              'id': 'u:70ac84d61ff3',
                              'label': 'Flex 布局',
                              'isFixedHeight': false
                            }
                          ],
                          'style': {
                            'position': 'static',
                            'display': 'flex',
                            'boxShadow': ' 0px 0px 0px 0px transparent',
                            'flexWrap': 'nowrap',
                            'width': '100%',
                            'overflowX': 'visible',
                            'margin': '0'
                          },
                          'wrapperBody': false,
                          'label': '',
                          'isFixedHeight': false,
                          'isFixedWidth': true
                        }
                      ],
                      'actions': [
                        {
                          'icon': '',
                          'type': 'button',
                          'id': 'u:f94b947d7575',
                          'label': '去处理'
                        }
                      ],
                      'id': 'u:f1394e000770'
                    },
                    'id': 'u:5fa98fcab469',
                    'source': '${alarmList}',
                    'className': 'no-border overflow-auto h-80',
                    'itemClassName': 'no-border'
                  }
                ],
                'size': 'xs',
                'style': {
                  'position': 'static',
                  'display': 'block',
                  'flex': '1 1 auto',
                  'flexGrow': 1,
                  'flexBasis': 'auto',
                  'boxShadow': ' 0px 0px 0px 0px transparent',
                  'marginTop': '10px',
                  'marginRight': '0',
                  'marginBottom': '0',
                  'marginLeft': '0'
                },
                'wrapperBody': false,
                'isFixedHeight': false,
                'isFixedWidth': false,
                'id': 'u:b2867f1a05ec'
              }
            ],
            'size': 'xs',
            'style': {
              'position': 'static',
              'display': 'flex',
              'flex': '1 1 auto',
              'flexGrow': 6,
              'flexBasis': 'auto',
              'boxShadow': ' 0px 0px 0px 0px transparent',
              'flexWrap': 'nowrap',
              'flexDirection': 'column',
              'paddingTop': '0',
              'paddingRight': '0',
              'paddingBottom': '0',
              'paddingLeft': '40px'
            },
            'wrapperBody': false,
            'isFixedHeight': false,
            'isFixedWidth': false,
            'id': 'u:537f65561217'
          }
        ],
        'style': {
          'position': 'relative',
          'inset': 'auto',
          'flexWrap': 'nowrap',
          'boxShadow': ' 0px 0px 0px 0px transparent'
        },
        'id': 'u:9854ac755d4a',
        'isFixedHeight': false,
        'isFixedWidth': false
      }
    ],
    'actions': [
      {
        'type': 'button',
        'label': '',
        'actionType': 'dialog',
        'dialog': {
          'title': '标题',
          'body': '内容'
        },
        'id': 'u:78e2e7098e35'
      }
    ]
  };

  // 容器类组件必需字段
  // regions = [
  //   {
  //     key: 'body',
  //     label: '内容区'
  //   }
  // ];

  panelTitle = '配置'; // 右侧属性面板Title

  panelControls = [ // 右侧属性面板配置项
    // {
    //   type: 'tabs',
    //   tabsMode: 'line',
    //   className: 'editor-prop-config-tabs',
    //   contentClassName: 'no-border editor-prop-config-tabs-cont hoverShowScrollBar',
    //   linksClassName: 'editor-prop-config-tabs-links aa',
    //   tabs: [
    //     {
    //       title: '常规',
    //       className: 'p-none',
    //       controls: [
    //         {
    //           'type': 'collapse-group',
    //           className: 'ae-formItemControl ae-styleControl',
    //           'activeKey': [
    //             'grid_11', 'grid_12'
    //           ],
    //           body: [
    //             {
    //               'type': 'collapse',
    //               'key': 'grid_11',
    //               'header': '基本',
    //               headingClassName: 'ae-formItemControl-header ae-Collapse-header',
    //               bodyClassName: 'ae-formItemControl-body',
    //               'body': [
    //                 {
    //                   type: 'input-text',
    //                   name: 'source_data',
    //                   label: '数据源',
    //                   value: 'deviceData',
    //                   // 'mode': 'inline',
    //                   'required': true

    //                 },
    //                 {
    //                   type: 'input-text',
    //                   name: 'grid_name',
    //                   label: '子设备名称',
    //                   value: 'deviceName',
    //                   // 'mode': 'inline',
    //                   'required': true
    //                 }
    //               ]
    //             },
    //             {
    //               'type': 'collapse',
    //               'key': 'grid_12',
    //               'header': '采集项',
    //               headingClassName: 'ae-formItemControl-header ae-Collapse-header',
    //               bodyClassName: 'ae-formItemControl-body',
    //               'body': [
    //                 {
    //                   type: 'input-text',
    //                   name: 'grid_child_data',
    //                   label: '采集项数据',
    //                   value: 'data',
    //                   'required': true

    //                 },
    //                 {
    //                   type: 'input-text',
    //                   name: 'grid_child_name',
    //                   label: '名称',
    //                   value: 'attrName',
    //                   // 'mode': 'inline',
    //                   'required': true
    //                 },
    //                 {
    //                   type: 'input-text',
    //                   name: 'grid_child_value',
    //                   label: '数值',
    //                   value: 'value',
    //                   // 'mode': 'inline',
    //                   'required': true
    //                 },
    //                 {
    //                   type: 'input-text',
    //                   name: 'grid_child_unit',
    //                   label: '单位',
    //                   value: 'attrUnit'
    //                   // 'mode': 'inline'
    //                 },
    //                 {
    //                   type: 'input-text',
    //                   name: 'grid_child_icon',
    //                   label: '图标',
    //                   value: 'icon'
    //                   // 'mode': 'inline'
    //                 }
    //               ]
    //             }
    //           ]
    //         }

    //       ]
    //     },
    //     {
    //       title: '外观',
    //       className: 'p-none',
    //       controls: [
    //         {
    //           'type': 'collapse-group',
    //           className: 'ae-formItemControl ae-styleControl',
    //           'activeKey': [
    //             'grid_21', 'grid_22', 'grid_23'
    //           ],
    //           'body': [
    //             {
    //               'type': 'collapse',
    //               'key': 'grid_21',
    //               'header': '行数量',
    //               headingClassName: 'ae-formItemControl-header ae-Collapse-header',
    //               bodyClassName: 'ae-formItemControl-body',
    //               'body': [
    //                 {
    //                   type: 'input-number',
    //                   name: 'grid_num',
    //                   label: '一行显示数量',
    //                   value: 5
    //                   // 'mode': 'inline'
    //                 }
    //               ]
    //             },
    //             {
    //               'type': 'collapse',
    //               'key': 'grid_22',
    //               'header': '显隐/排序设置',
    //               headingClassName: 'ae-formItemControl-header ae-Collapse-header',
    //               bodyClassName: 'ae-formItemControl-body',
    //               'body': [
    //                 {
    //                   'type': 'switch',
    //                   'label': '状态',
    //                   'option': '',
    //                   'name': 'grid_edit',
    //                   'falseValue': false,
    //                   'trueValue': true,
    //                   'value': true,
    //                   'onText': '启用',
    //                   'offText': '禁用'
    //                 },
    //                 {
    //                   'type': 'input-text',
    //                   'label': '显隐key',
    //                   'name': 'grid_check_key',
    //                   'value': 'attrId',
    //                   'hiddenOn': '${AND(!grid_edit)}'
    //                 }
    //               ]
    //             },
    //             {
    //               'type': 'collapse',
    //               'key': 'grid_23',
    //               'header': '间距',
    //               headingClassName: 'ae-formItemControl-header ae-Collapse-header',
    //               bodyClassName: 'ae-formItemControl-body',
    //               'body': [
    //                 {
    //                   type: 'input-number',
    //                   name: 'grid_margin_bottom',
    //                   label: '行间距',
    //                   value: 5
    //                   // 'mode': 'inline'
    //                 },
    //                 {
    //                   type: 'input-number',
    //                   name: 'grid_padding_top',
    //                   label: '上边距',
    //                   value: 10
    //                   // 'mode': 'inline'
    //                 },
    //                 {
    //                   type: 'input-number',
    //                   name: 'grid_padding_left',
    //                   label: '左边距',
    //                   value: 0
    //                   // 'mode': 'inline'
    //                 },
    //                 {
    //                   type: 'input-number',
    //                   name: 'grid_padding_bottom',
    //                   label: '下边距',
    //                   value: 10
    //                   // 'mode': 'inline'
    //                 },
    //                 {
    //                   type: 'input-number',
    //                   name: 'grid_padding_right',
    //                   label: '右边距',
    //                   value: 0
    //                   // 'mode': 'inline'
    //                 }
    //               ]
    //             },
    //             {
    //               'type': 'collapse',
    //               'key': 'grid_23',
    //               'header': '颜色',
    //               headingClassName: 'ae-formItemControl-header ae-Collapse-header',
    //               bodyClassName: 'ae-formItemControl-body',
    //               'body': [
    //                 {
    //                   'type': 'input-color',
    //                   'label': '背景色',
    //                   'name': 'grid_background',
    //                   'format': 'rgba',
    //                   'value': 'rgba(255, 255, 255, 0)',
    //                   'labelRemark': {
    //                     'icon': 'fa fa-question-circle',
    //                     'trigger': [
    //                       'hover'
    //                     ],
    //                     'className': 'Remark--warning',
    //                     'placement': 'top',
    //                     'content': '设置整个区域的背景色'
    //                   },
    //                   'allowCustomColor': true
    //                   // 'mode': 'inline'
    //                 },
    //                 {
    //                   'type': 'input-color',
    //                   'label': '名称颜色',
    //                   'name': 'grid_name_color',
    //                   'format': 'rgba',
    //                   'value': 'rgb(87, 93, 108, 1)',
    //                   'labelRemark': {
    //                     'icon': 'fa fa-question-circle',
    //                     'trigger': [
    //                       'hover'
    //                     ],
    //                     'className': 'Remark--warning',
    //                     'placement': 'top',
    //                     'content': '设置名称颜色'
    //                   },
    //                   'resetValue': 'rgb(87, 93, 108, 1)',
    //                   'allowCustomColor': true
    //                   // 'mode': 'inline'
    //                 },
    //                 {
    //                   'type': 'input-color',
    //                   'label': '数值颜色',
    //                   'name': 'grid_value_color',
    //                   'format': 'rgba',
    //                   'value': 'rgb(28, 31, 39, 1)',
    //                   'labelRemark': {
    //                     'icon': 'fa fa-question-circle',
    //                     'trigger': [
    //                       'hover'
    //                     ],
    //                     'className': 'Remark--warning',
    //                     'placement': 'top',
    //                     'content': '设置数值颜色'
    //                   },
    //                   'resetValue': 'rgb(28, 31, 39, 1)',
    //                   'allowCustomColor': true
    //                   // 'mode': 'inline'
    //                 }
    //               ]
    //             }
    //           ]
    //         }
    //       ]
    //     },
    //     {
    //       title: '阈值',
    //       className: 'p-none',
    //       controls: [
    //         {
    //           'type': 'collapse-group',
    //           className: 'ae-formItemControl ae-styleControl',
    //           'activeKey': [
    //             'grid_31', 'grid_32'
    //           ],
    //           'body': [
    //             {
    //               'type': 'collapse',
    //               'key': 'grid_31',
    //               'header': '阈值数据设置',
    //               headingClassName: 'ae-formItemControl-header ae-Collapse-header',
    //               bodyClassName: 'ae-formItemControl-body',
    //               'body': [
    //                 {
    //                   type: 'input-text',
    //                   name: 'grid_alarm_data',
    //                   label: '阈值数据',
    //                   value: 'threshold'
    //                   // 'mode': 'inline'
    //                 }
    //               ]
    //             },
    //             {
    //               'type': 'collapse',
    //               'key': 'grid_32',
    //               'header': '阈值显示设置',
    //               headingClassName: 'ae-formItemControl-header ae-Collapse-header',
    //               bodyClassName: 'ae-formItemControl-body',
    //               'body': [
    //                 {
    //                   type: 'input-text',
    //                   name: 'grid_alarm_max',
    //                   label: '对应最大值',
    //                   value: 'max'
    //                   // 'mode': 'inline'
    //                 },
    //                 {
    //                   type: 'input-text',
    //                   name: 'grid_alarm_min',
    //                   label: '对应最小值',
    //                   value: 'min'
    //                   // 'mode': 'inline'
    //                 },
    //                 {
    //                   'type': 'input-text',
    //                   'label': '对应显示颜色',
    //                   'name': 'grid_alarm_color',
    //                   value: 'color'
    //                 }
    //               ]
    //             }
    //           ]
    //         }
    //       ]
    //     }
    //   ]

    // }

  ];
}

registerEditorPlugin(InfoGridPlugin)
