<template>
  <div>
    <a-popover
      v-model="visible"
      trigger="click"
      placement="bottomLeft"
      overlayClassName="header-notice-wrapper"
      :getPopupContainer="() => $refs.noticeRef.parentElement"
      :autoAdjustOverflow="true"
      :arrowPointAtCenter="true"
      :overlayStyle="{ width: '300px', top: '50px' }"
    >
      <template slot="content">
        <a-spin :spinning="loading">
          <a-tabs v-model="queryParam.newsType" @change="changeType">
            <a-tab-pane v-for="d in typeOptions" :key="d.dictValue" :tab="d.dictLabel">
              <a-list style="max-height: 300px; overflow:auto;cursor: pointer;">
                <div
                  v-if="showLoadingMore"
                  slot="loadMore"
                  :style="{ textAlign: 'center', marginTop: '12px', height: '32px', lineHeight: '32px' }"
                >
                  <a-spin v-if="loadingMore" />
                  <a-button v-else @click="onLoadMore">
                    查看更多
                  </a-button>
                </div>
                <a-list-item v-for="(item, index) in list" :key="index" @click="$refs.noticeDetail.getNotice(item)">
                  <a-list-item-meta :description="item.createTime">
                    <a slot="title">
                      
                      <ellipsis :length="32" tooltip>{{ item.newsTitle }}</ellipsis>
                      <a-badge v-if="item.flage==1" status="error"></a-badge>
                    </a>
                  </a-list-item-meta>
                </a-list-item>
              </a-list>
            </a-tab-pane>
          </a-tabs>
        </a-spin>
      </template>
      <span class="header-notice" ref="noticeRef">
        <!-- <a-icon style="font-size: 20px;" type="bell" /> -->
        <a-tooltip placement="bottom">
          <template slot="title">
            通知消息
          </template>
          <a-badge :offset="[5,2]" :count="msgCount" :overflow-count="999">
            <img style="height: 28px;" src="@/assets/topBtn/3.png" @click.stop.prevent="fetchNotice">

          </a-badge>
        </a-tooltip>
        <!-- <a-badge :offset="[5,2]" :count="11110" :overflow-count="999">
          <img style="height: 22px;" src="@/assets/topBtn/3.png" @click.stop.prevent="fetchNotice">
        </a-badge> -->

      </span>

    </a-popover>
    <notice-detail ref="noticeDetail" :typeOptions="typeOptions"  @updateNum = "(getList(),getMsgNum())"/>
  </div>
</template>

<script>
import { newsYsjPage,newsYsjNum } from '@/api/system/notice'
import Ellipsis from '@/components/Ellipsis'
import NoticeDetail from './NoticeDetail'

export default {
  name: 'HeaderNotice',
  components: {
    Ellipsis,
    NoticeDetail
  },
  data () {
    return {
      loading: false,
      loadingMore: false,
      showLoadingMore: true,
      visible: false,
      queryParam: {
        pageNum: 1,
        pageSize: 5,
        status: 0,
        newsClient: 2, // 	终端类型 1-小程序 2-web 3-app 4-公众号
        newsType: '1' // 	消息类型: 0-系统消息 1-活动消息
      },
      list: [],
      typeOptions: [],
      msgCount:0, // 消息未读数量
      timeInt:null, // 时间计时器
    }
  },
  created(){
    this.getMsgNum()
    if(this.timeInt) {
      clearInterval(this.timeInt)
      this.timeInt = null
    }
    this.timeInt = setInterval(() => {
      this.getMsgNum()
    }, 1000*60);
  },
  beforeDestroy(){
    if(this.timeInt) {
      clearInterval(this.timeInt)
      this.timeInt = null
    }
  },
  methods: {
    getList () {
      this.loading = true
      newsYsjPage(this.queryParam).then(response => {
          this.list = response.data.records
          this.total = response.data.total
          if (this.total <= this.queryParam.pageNum * this.queryParam.pageSize) {
            this.showLoadingMore = false
          } else {
            this.showLoadingMore = true
          }
          this.loading = false
        }
      )
    },
    // 获取消息数量
    getMsgNum() {
      // newsYsjNum().then(res => {
      //   let hdNum = res.data.hdNum?Number(res.data.hdNum):0
      //   let xtNum = res.data.xtNum?Number(res.data.xtNum):0
      //   this.msgCount = hdNum + xtNum
      //   console.log('消息数量>>>>',this.msgCount);
        
      // })
    },
    fetchNotice () {
      console.log('>>>>>>>>>');
      
      this.resetQuery()
      if (!this.visible) {
        if (this.typeOptions.length === 0) {
          this.getDicts('sys_notice_type').then(response => {
            this.typeOptions = response.data
          })
        }
        this.getList()
      }
      this.visible = !this.visible
    },
    resetQuery () {
      this.queryParam = {
        pageNum: 1,
        pageSize: 5,
        status: 0,
        newsClient: 2, // 	终端类型 1-小程序 2-web 3-app 4-公众号
        newsType: '0' // 	消息类型: 0-系统消息 1-活动消息
      }
      this.list = []
    },
    changeType (key) {
      this.resetQuery()
      this.queryParam.newsType = key
      this.getList()
    },
    onLoadMore () {
      this.loadingMore = true
      this.queryParam.pageNum++
      this.getList()
      this.loadingMore = false
    }
  }
}
</script>

<style lang="css" scoped>
  .header-notice-wrapper {
    top: 50px !important;
  }
</style>
<style lang="less" scoped>
  .header-notice{
    display: inline-block;
    transition: all 0.3s;

    span {
      vertical-align: initial;
    }

    /deep/.ant-badge-count {
      height: 13px;
      padding: 0 6px;
      font-size: 8px;
      font-weight: bold;
      font-family: '思源-Bold';
      line-height: 13px;
    }
  }
</style>
